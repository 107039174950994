import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Nav, Navbar, NavItem} from 'reactstrap';
import {getUser, logout} from "../ducks/user";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

const renderLoginButton = (user, logout) => {
    if (user === null) {
        return (
            <Link to={"/login"} className={'btn btn-primary'}>Вход</Link>
        )
    } else {
        return (
            <Button color={'success'} onClick={() => logout()}>
                ({user.username}) Выход
            </Button>
        )
    }
};

const NavBarComponent = (props) => (
    <Navbar color="light" light expand="md" sticky={'top'}>
        <Nav className={"mr-auto"}>
            <NavItem>
                <Link to={"/"} className={'nav-link'}>Igames</Link>
            </NavItem>
            <NavItem>
                <Link to={`/user-screen/si`} className={'btn btn-success nav-link mr-2'}>Общий экран</Link>
            </NavItem>
            <NavItem>
                <Link to={`/presenter-screen/si`} className={'btn btn-warning nav-link mr-2'}>Экран ведущего</Link>
            </NavItem>
            <NavItem>
                <Link to={`/player-screen`} className={'btn btn-info nav-link mr-2'}>Экран игрока</Link>
            </NavItem>
        </Nav>
        <Nav className={"ml-auto"}>

            <NavItem>
                {renderLoginButton(props.user, props.logout)}
            </NavItem>
        </Nav>
    </Navbar>
);

const mapStateToProps = (state) => ({
   user: getUser(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({logout}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (NavBarComponent);
