import * as ApiUser from '../api/user';
import {call, put, takeEvery} from 'redux-saga/effects';
import {connectWebsocket, disconnectWebsocket} from "./ws";

//actions

const LOGIN = 'igames/user/LOGIN';
const LOGOUT = 'igames/user/LOGOUT';
const UPDATE = "igames/user/UPDATE";
const ERROR = 'igames/user/ERROR';
const LIST = 'igames/user/LIST';
const LIST_SUCCEED = 'igames/user/LIST_SUCCEED';

const initialState = {
    user: null,
    errors: [],
    loading: false,
    list: [],
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: null,
                loading: true,
                errors: [],
            };
        case UPDATE:
            return {
                ...state,
                user: action.payload,
                loading: false,
                errors: [],
            };
        case LIST:
            return {
                ...state,
                list: [],
                loading: true,
                errors: [],
            };
        case LIST_SUCCEED:
            return {
                ...state,
                list: action.payload,
                loading: false,
                errors: [],
            };
        case ERROR:
            return {
                ...state,
                user: null,
                errors: action.payload,
                loading: false,
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
                errors: [],
                loading: false,
            };
        default:
            return state;
    }
};

//selectors

export function getUser(state) {
    return state.user.user;
}

export function getUserLoading(state) {
    return state.user.loading;
}

export function getUserErrors(state) {
    return state.user.errors;
}

export function isAuthenticated(state) {
    return getUser(state) !== null;
}

export function getList(state) {
    return state.user.list;
}

//action creators
export function login(loginData) {
    return {
        type: LOGIN,
        payload: loginData
    }
}

export function logout() {
    return {type: LOGOUT}
}

export function list() {
    return {type: LIST}
}

export function listSucceed(list) {
    return {
        type: LIST_SUCCEED,
        payload: list,
    }
}

export function update(user) {
    return {
        type: UPDATE,
        payload: user
    }
}

export function error(errors) {
    return {
        type: ERROR,
        payload: errors
    }
}

//sagas

function* fetchLogin(action) {
    try {
        const response = yield call(ApiUser.login, action.payload);
        if (response.status === true) {
            yield put(update(response.payload));
            yield put(connectWebsocket())
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        yield put(error([{message: e.message}]))
    }
}

function* fetchList(action) {
    try {
        const response = yield call(ApiUser.list);
        if (response.status === true) {
            yield put(listSucceed(response.payload));
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        yield put(error([{message: e.message}]))
    }
}

function* logoutSaga() {
    yield put(disconnectWebsocket());
}

export function* userSaga() {
    yield takeEvery(LOGOUT, logoutSaga);
    yield takeEvery(LOGIN, fetchLogin);
    yield takeEvery(LIST, fetchList);
}

