import React from 'react';
import {Button, Card, CardHeader, Form, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {bindActionCreators} from 'redux';
import {add, edit, hideAddItem, hideEditItem} from '../ducks/gameResultIndex';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

class GameResultEditItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
        }
    }

    setName(name) {
        this.setState((prevState) => ({
            ...prevState,
            item: {
                ...prevState.item,
                name: name,
            }
        }));
    }

    onSubmit(event) {
        const item = this.state.item;
        if (item._id === undefined) {
            console.log(item);
            this.props.add(item, this.props.gameType);
        } else {
            this.props.edit(item, this.props.gameType);
        }
        event.preventDefault();
    }

    onCancel() {
        const item = this.state.item;
        if (item._id === undefined) {
            this.props.hideAddItem();
        } else {
            this.props.hideEditItem();
        }
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    <Form onSubmit={(event) => this.onSubmit(event)}>
                        <InputGroup>
                            <Input
                                type={'text'}
                                placeholder={'Название турнира'}
                                value={this.state.item.name}
                                onChange={(event) => this.setName(event.target.value)}
                            />
                            <InputGroupAddon addonType={'append'}>
                                <Button onClick={(event) => this.onSubmit(event)} color={'success'}>
                                    <FontAwesomeIcon icon={faCheck}/>
                                </Button>
                                <Button onClick={() => this.onCancel()}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Form>
                </CardHeader>
            </Card>
        );
    }
}

GameResultEditItem.propTypes = {
    item: PropTypes.object.isRequired,
    gameType: PropTypes.string.isRequired,
    gameId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({add, edit, hideAddItem, hideEditItem}, dispatch);
};

export default connect(null, mapDispatchToProps)(GameResultEditItem);
