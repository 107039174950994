import React from 'react';
import {Card, CardHeader, Col, Form, FormGroup, Input, Row} from 'reactstrap';
import SvoyaIgraGameEdit from "./svoyaigra/SvoyaIgraGameEditItem";
import ChgkGameEditItem from "./chgk/ChgkGameEditItem";
import BrainRingGameEditItem from "./brain/BrainRingGameEditItem";
import PropTypes from 'prop-types';
import * as GameType from '../models/GameType';
import {getAddGameItem} from "../ducks/tournamentView";
import {connect} from 'react-redux';

class GameAddItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            game_type: null
        }
    }

    setGameType(game_type) {
        this.setState((prevState) => ({
            ...prevState,
            game_type: game_type
        }));
    }

    render() {
        let gameComponent = null;
        switch (this.state.game_type) {
            case 'si':
                gameComponent = <SvoyaIgraGameEdit item={this.props.item}/>;
                break;
            case 'chgk':
                gameComponent = <ChgkGameEditItem item={this.props.item}/>;
                break;
            case 'br':
                gameComponent = <BrainRingGameEditItem item={this.props.item}/>;
                break;
            default:
        }

        return (
            <Card>
                <CardHeader>
                    <Form>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Input type="select"
                                           onChange={(event) => this.setGameType(event.target.value)}>
                                        <option value={null}>Выберите тип игры</option>
                                        {Object.keys(GameType.available).map(
                                            (key) => {
                                                    const value = GameType.available[key];
                                                    return <option key={key} value={key}>{value}</option>
                                                }
                                            )
                                        }

                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        {gameComponent}
                    </Form>
                </CardHeader>
            </Card>
        );
    }
}

GameAddItem.propTypes = {
    item: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    item: getAddGameItem(state),
});

export default connect(mapStateToProps, null) (GameAddItem);
