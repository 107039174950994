import React, {Component} from 'react';
import {getActiveQuestion, getActiveRound, getItem} from "../../ducks/gameResultView";
import {connect} from "react-redux";
import SvoyaIgraPresenterScreenQuestionTable from "./presenterScreen/SvoyaIgraPresenterScreenQuestionTable";
import SvoyaIgraPresenterScreenPlayersPanel from "./presenterScreen/SvoyaIgraPresenterScreenPlayersPanel";
import SvoyaIgraPresenterScreenQuestionModal from "./presenterScreen/SvoyaIgraPresenterScreenQuestionModal";

class SvoyaIgraGamePresenterScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: 0,
            windowHeight: 0,
        }
    }

    updateDimensions() {
        this.setState((prevState, props) => ({
            ...prevState,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        }))
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <>
                <SvoyaIgraPresenterScreenQuestionModal gameType={this.props.gameType}/>
                <SvoyaIgraPresenterScreenQuestionTable
                    round={this.props.activeRound}
                    width={this.state.windowWidth}
                    height={this.state.windowHeight - 150}
                />
                <SvoyaIgraPresenterScreenPlayersPanel players={this.props.item.players}/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    item: getItem(state),
    activeRound: getActiveRound(state),
    activeQuestion: getActiveQuestion(state),
});

export default connect(mapStateToProps, null)(SvoyaIgraGamePresenterScreen);
