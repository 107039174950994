import {BACKEND_URL, getToken} from "./common";

async function image(file) {
    const url = `${BACKEND_URL}/v1/upload/image`;
    const formData = new FormData();
    formData.append('imageFile', file, file.name);

    let response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function audio(file) {
    const url = `${BACKEND_URL}/v1/upload/audio`;
    const formData = new FormData();
    formData.append('audioFile', file, file.name);

    let response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function video(file) {
    const url = `${BACKEND_URL}/v1/upload/video`;
    const formData = new FormData();
    formData.append('videoFile', file, file.name);

    let response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

export {image, audio, video};