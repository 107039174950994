import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {del, getEditItem, hideEditItem, showEditItem} from '../ducks/tournamentIndex';
import TournamentEditItem from './TournamentEditItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPen} from '@fortawesome/free-solid-svg-icons';
import DeleteButton from "./DeleteButton";

class TournamentItem extends React.Component {

    onDelete = () => {
        this.props.del(this.props.item._id);
    };

    onEdit = () => {
        this.props.showEditItem(this.props.item);
    };

    render() {

        if (this.props.editItem !== null && this.props.editItem._id === this.props.item._id) {
            return <TournamentEditItem item={this.props.editItem}/>
        }

        return (<>
            <Card className={'my-3'}>
                <CardHeader>
                    <Row>
                        <Col>
                            <h4>
                                {this.props.item.name}
                                <Button color={'link'} onClick={() => this.onEdit()}>
                                    <FontAwesomeIcon icon={faPen}/>
                                </Button>
                            </h4>
                        </Col>
                        <Col>
                            <span className={'float-right'}>
                                <Link className={'btn btn-success mx-1'} to={`/tournament/${this.props.item._id}`}>
                                    Подробнее
                                </Link>
                                <DeleteButton onDelete={() => this.onDelete()}/>
                            </span>

                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            Игр добавлено: {this.props.item.gamesCount}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>);
    }
}

TournamentItem.propTypes = {
    item: PropTypes.object,
};

const mapStateToProps = (state) => ({
    editItem: getEditItem(state)
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({del, showEditItem, hideEditItem}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (TournamentItem);