import {BACKEND_URL, getToken} from "./common";

async function index() {
    const url = `${BACKEND_URL}/v1/tournament`;

    let response = await fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function add(tournament) {
    const url = `${BACKEND_URL}/v1/tournament`;

    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(tournament),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function edit(tournament) {
    const url = `${BACKEND_URL}/v1/tournament/${tournament._id}`;

    let response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(tournament),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function del(tournamentId) {
    const url = `${BACKEND_URL}/v1/tournament/${tournamentId}`;

    return await fetch(url, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
}

async function addGame(game_type, game) {
    const url = `${BACKEND_URL}/v1/game/${game_type}`;

    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(game),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function view(id) {
    const url = `${BACKEND_URL}/v1/tournament/${id}?expand=games`;

    let response = await fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

export {index, add, view, addGame, edit, del};