import React from 'react';
import SvoyaIgraQuestionEditItem from "./SvoyaIgraQuestionEditItem";
import {deleteQuestion, getEditQuestionItem, showEditQuestionItem} from "../../ducks/gameView";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Badge, Button, Card, CardBody, CardHeader, Col, Row,} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {TYPE_SI} from "../../models/GameType";
import PropTypes from 'prop-types';
import {getName, TYPE_AUDIO, TYPE_IMAGE, TYPE_VIDEO} from "../../models/SvoyaIgraQuestionType";
import {UNKNOWN_IMAGE_URL} from "../../api/common";
import ReactAudioPlayer from 'react-audio-player';
import DeleteButton from "../DeleteButton";
import ReactPlayer from 'react-player'

const renderUrl = (question) => {
    if (question.question_type_id === TYPE_IMAGE) {
        const url = question.url || UNKNOWN_IMAGE_URL;
        return <img alt={'Изображение вопроса'} src={url} className={'SvoyaIgra-question-item-image img-thumbnail mr-2'}/>
    } else if (question.question_type_id === TYPE_AUDIO) {
        if (question.url) {
            return <ReactAudioPlayer src={question.url} controls controlsList={'nodownload'}/>
        } else {
            return <span>Аудио не загружено</span>
        }
    } else if (question.question_type_id === TYPE_VIDEO) {
        if (question.url) {
            return <ReactPlayer url={question.url} width={160} height={120}/>
        } else {
            return <span>Видео не загружено</span>
        }
    }
};

const SvoyaIgraQuestionItem = (props) => {
    if (props.editItem !== null && props.item._id === props.editItem._id) {
        return (
            <div className="my-1">
                <SvoyaIgraQuestionEditItem item={props.editItem}/>
            </div>
        );
    }

    return (
        <Card className={'my-2'}>
            <CardHeader>
                <Row>
                    <Col>
                        <h4>{props.item.price}</h4>
                    </Col>
                    <Col>
                        <h4><Badge color={'info'}>{getName(props.item.question_type_id)}</Badge></h4>
                    </Col>
                    <Col className={'col-sm-auto'}>
                        <Button color={'link'}
                                onClick={() => props.showEditQuestionItem(props.item)}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </Button>
                        <DeleteButton onDelete={() => props.deleteQuestion(props.item, TYPE_SI)}/>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Row noGutters>
                    <Col className={'col-md-auto'}>
                        {renderUrl(props.item)}
                    </Col>
                    <Col>
                        {props.item.question}
                    </Col>
                    <Col>
                        {props.item.answer}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

SvoyaIgraQuestionItem.propTypes = {
    item: PropTypes.object.isRequired,
    editItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
    editItem: getEditQuestionItem(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showEditQuestionItem, deleteQuestion}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (SvoyaIgraQuestionItem);