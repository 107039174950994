import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import PropTypes from 'prop-types'

const ListHeader = (props) => (
    <>
        <hr/>
        <Row>
            <Col>
                <h2>{props.header}</h2>
            </Col>
            {props.displayAddButton &&
            <Col>
                <Button className={'float-right'} onClick={props.onAddButtonClick}>
                    {props.addButtonText}
                </Button>
            </Col>
            }
        </Row>
        <hr/>
    </>
);

ListHeader.propTypes = {
    header: PropTypes.string,
    onAddButtonClick: PropTypes.func,
    addButtonText: PropTypes.string,
    displayAddButton: PropTypes.bool,
};

ListHeader.defaultProps = {
    displayAddButton: true,
};

export default ListHeader;