import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {
    getActiveQuestion,
    getAddQuestionResultItem,
    getItem,
    hideAddQuestionResultItem,
    unsetActiveQuestion
} from "../../../ducks/gameResultView";
import SvoyaIgraGameResultModalPlayersPanel from "./SvoyaIgraGameResultModalPlayersPanel";
import {TYPE_AUDIO, TYPE_VIDEO} from "../../../models/SvoyaIgraQuestionType";
import AudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";

class SvoyaIgraGameResultQuestionModal extends Component {

    isModalDisplayed() {
        return this.props.question !== undefined && this.props.question !== null;
    }

    render() {
        const question = this.props.question;

        if (!this.isModalDisplayed()) {
            return null;
        }


        const player = this.props.addQuestionResultItem ?
            this.props.players.find((element) => element._id === this.props.addQuestionResultItem.player_id)
            : null;

        return (
            <Modal isOpen={true}>
                <ModalHeader toggle={() => {
                    this.props.unsetActiveQuestion();
                    this.props.hideAddQuestionResultItem();
                }}>
                    {question.price} {player ? "- " + player.name : null}
                </ModalHeader>
                <ModalBody>
                    {this.props.question.question_type_id == TYPE_AUDIO
                        && <AudioPlayer
                            autoPlay={true}
                            src={this.props.question.url} controls controlsList={'nodownload'}
                        />
                    }
                    {this.props.question.question_type_id == TYPE_VIDEO
                        && <ReactPlayer url={this.props.question.url} width={160} height={120}/>
                    }
                    <h4>Вопрос</h4>
                    {question.question}
                    <hr/>
                    <h4>Ответ</h4>
                    {question.answer}
                </ModalBody>
                <ModalFooter>
                    <SvoyaIgraGameResultModalPlayersPanel players={this.props.players}
                                                          gameType={this.props.gameType}/>
                </ModalFooter>
            </Modal>
        );
    }
}

SvoyaIgraGameResultQuestionModal.propTypes = {
    gameType: PropTypes.string.isRequired,
    question: PropTypes.object,
    players: PropTypes.array.isRequired,
    addQuestionResultItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
    question: getActiveQuestion(state),
    players: getItem(state).players,
    addQuestionResultItem: getAddQuestionResultItem(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    unsetActiveQuestion: unsetActiveQuestion,
    hideAddQuestionResultItem: hideAddQuestionResultItem,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultQuestionModal);
