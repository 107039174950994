import {BACKEND_URL, getToken} from "./common";

async function view(gameId, gameType) {
    const url = `${BACKEND_URL}/v1/game/${gameType}/${gameId}?expand=rounds`;

    let response = await fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function add(gameType, game) {
    const url = `${BACKEND_URL}/v1/game/${gameType}`;

    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(game),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function edit(game) {
    const url = `${BACKEND_URL}/v1/game/${game.game_type_id}/${game._id}`;

    let response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(game),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });

    response = response.json();
    return response;
}

async function del(game) {
    const url = `${BACKEND_URL}/v1/game/${game.game_type_id}/${game._id}`;

    return await fetch(url, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
}

export {view, add, edit, del};