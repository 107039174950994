import React from 'react';
import {getErrors, getItem, view} from '../ducks/gameView';
import {getItem as getTournamentItem} from '../ducks/tournamentView';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Breadcrumb, BreadcrumbItem,} from 'reactstrap';
import {Link} from 'react-router-dom';
import {TYPE_SI} from "../models/GameType";
import SvoyaIgraGameView from "./svoyaigra/SvoyaIgraGameView";
import {renderErrors} from "./common";


class GameView extends React.Component {

    componentDidMount() {
        const {gameId, gameType} = this.props.match.params;
        this.props.view(gameId, gameType);
    }

    renderView = (item, gameType) => {
        switch (gameType) {
            case TYPE_SI:
                return <SvoyaIgraGameView item={item}/>;
            default:
                return null;
        }
    };

    render() {
        const item = this.props.item;
        const tournament = this.props.tournament;
        const {gameType} = this.props.match.params;
        if(item === null) {
            return <h2>Загрузка данных</h2>
        }

        return (
            <>
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/'}>Список турниров</Link></BreadcrumbItem>
                    <BreadcrumbItem>
                        {tournament !== null
                            &&
                            <Link to={`/tournament/${tournament._id}`}>
                                {tournament.name}
                            </Link>
                        }
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        <Link to={`/game/${gameType}/${item._id}`}>
                            {item.name}
                        </Link>
                    </BreadcrumbItem>
                </Breadcrumb>
                {renderErrors(this.props.errors)}
                {this.renderView(item, gameType)}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    item: getItem(state),
    tournament: getTournamentItem(state),
    errors: getErrors(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({view}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (GameView);