import React from 'react';
import PropTypes from 'prop-types';
import SvoyaIgraAddQuestionCategory from './SvoyaIgraAddQuestionCategory';
import SvoyaIgraQuestionCategoryItem from './SvoyaIgraQuestionCategoryItem';

const SvoyaIgraRoundView = (props) => {

    if (props.round) {
        return (
            <>
                {props.round.categories.map((item) =>
                    <SvoyaIgraQuestionCategoryItem key={item._id} item={item}/>)}
                <SvoyaIgraAddQuestionCategory round={props.round}/>
            </>
        )
    }
    return null;
};

SvoyaIgraRoundView.propTypes = {
    round: PropTypes.object,
};

export default SvoyaIgraRoundView;