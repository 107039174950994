import React, {Component} from 'react';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import {getUser, getUserErrors, getUserLoading, isAuthenticated, login} from "../ducks/user";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Redirect} from 'react-router-dom';
import {renderErrors} from "./common";

const state = {
    username : '',
    password: '',
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = state;
    }

    onSumbit = () => {
        this.props.login(this.state);
    };

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to={'/'}/>
        }

        return (
            <div className="container h-100 d-flex justify-content-center">
                <Form>
                    <FormGroup>
                        {renderErrors(this.props.errors)}
                    </FormGroup>
                    <FormGroup>
                        <Label>Имя пользователя</Label>
                        <Input type={"text"}
                               name={"username"}
                               placeholder={"Имя пользователя"}
                               onChange={(event) => {
                                        const value = event.target.value;
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            username: value
                                        }));
                                   }
                               }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Пароль</Label>
                        <Input type={"password"}
                               name={"password"}
                               placeholder={"Пароль"}
                               onChange={(event) => {
                                   const value = event.target.value;
                                   this.setState((prevState) => ({
                                       ...prevState,
                                       password: value
                                   }));
                               }
                               }
                        />
                    </FormGroup>
                    <Button onClick={() => this.onSumbit()} block={true}>Вход</Button>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        user: getUser(state),
        isAuthenticated: isAuthenticated(state),
        errors: getUserErrors(state),
        loading: getUserLoading(state),
    }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({login}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (Login);
