import React from 'react';
import PropTypes from 'prop-types';
import SvoyaIgraGameResultQuestionCategory from "./SvoyaIgraGameResultQuestionCategory";

class SvoyaIgraGameResultQuestionTable extends React.Component {
    render() {
        if (!this.props.round) {
            return null;
        }
        return this.props.round.categories.map((item) =>
            <SvoyaIgraGameResultQuestionCategory key={item._id} category={item}/>);
    }
}

SvoyaIgraGameResultQuestionTable.propTypes = {
    round: PropTypes.object.isRequired,
};

export default SvoyaIgraGameResultQuestionTable;
