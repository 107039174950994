class QuestionResult {
    static create = (gameResult, question, player, gameType) => {
        const playerId = player ? player._id : null;
        return {
            question_id: question._id,
            player_id: playerId,
            game_result_id: gameResult._id,
            cost: question.price,
        };
    }
}

export default QuestionResult;