
const TYPE_TEXT = 'text';
const TYPE_IMAGE = 'image';
const TYPE_AUDIO = 'audio';
const TYPE_VIDEO = 'video';

const available = {
    text: 'Текст',
    image: 'Изображение',
    audio: 'Аудио',
    video: 'Видео',
};

const withFile = [TYPE_IMAGE, TYPE_AUDIO, TYPE_VIDEO];

const getName = (questionType) => {
    if (available[questionType] !== undefined) {
        return available[questionType];
    }
    return 'Неизвестный тип вопроса';
};

export {available, getName, TYPE_TEXT, TYPE_IMAGE, TYPE_AUDIO, TYPE_VIDEO, withFile};