import React from 'react';
import PropTypes from 'prop-types';
import SvoyaIgraUserScreenQuestionCategory from "./SvoyaIgraUserScreenQuestionCategory";

class SvoyaIgraUserScreenQuestionTable extends React.Component {
    render() {
        if (this.props.round == null) {
            return null;
        }

        const rowHeight = this.props.height / this.props.round.categories.length;
        return this.props.round.categories.map((item) =>
            <SvoyaIgraUserScreenQuestionCategory key={item._id} category={item} height={rowHeight}/>);
    }
}

SvoyaIgraUserScreenQuestionTable.propTypes = {
    round: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default SvoyaIgraUserScreenQuestionTable;
