import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addRound, editRound, hideAddRoundItem, hideEditRoundItem} from '../../ducks/gameView';
import {TYPE_SI} from "../../models/GameType";

class SvoyaIgraRoundEditItem extends Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({}, this.props.item);
    }

    setName(name) {
        this.setState((prevState) => ({
            ...prevState,
            name: name,
        }));
    }

    onSubmit(event) {
        if (this.state._id !== undefined) {
            this.props.editRound(this.state, TYPE_SI);
        } else {
            this.props.addRound(this.state, TYPE_SI);
        }
        event.preventDefault();
    }

    onCancel() {
        if (this.state._id !== undefined) {
            this.props.hideEditRoundItem()
        } else {
            this.props.hideAddRoundItem()
        }
    }

    render() {
        return (
            <Form onSubmit={(event) => this.onSubmit(event)}>
                <InputGroup>
                    <Input
                        type={'text'}
                        placeholder={'Название раунда'}
                        value={this.state.name}
                        onChange={(event) => this.setName(event.target.value)}
                    />
                    <InputGroupAddon addonType={'append'}>
                        <Button onClick={(event) => this.onSubmit(event)} color={'success'}>
                            <FontAwesomeIcon icon={faCheck}/>
                        </Button>
                        <Button onClick={() => this.onCancel()}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>

            </Form>
        );
    }
}

SvoyaIgraRoundEditItem.propTypes = {
    item: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({hideAddRoundItem, hideEditRoundItem, addRound, editRound}, dispatch);
};

export default connect(null, mapDispatchToProps) (SvoyaIgraRoundEditItem);
