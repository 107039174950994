import React from 'react';
import {Button} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {TYPE_SI} from "../../../models/GameType";
import SvoyaIgraGameResultPlayerEditItem from "./SvoyaIgraGameResultPlayerEditItem";
import {getAddPlayerItem, getItem, showAddPlayerItem} from "../../../ducks/gameResultView";

class SvoyaIgraGameResultAddPlayer extends React.Component {
    render() {
        const addItem = this.props.addItem;
        const gameResult = this.props.gameResult;
        if (addItem !== null) {
            return <SvoyaIgraGameResultPlayerEditItem item={addItem}/>
        }

        return (
            <Button className={'btn btn-primary btn-block'}
                    onClick={() => this.props.showAddPlayerItem(gameResult, TYPE_SI)}>
                Добавить игрока
            </Button>
        )
    }
}

SvoyaIgraGameResultAddPlayer.propTypes = {
    gameResult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    addItem: getAddPlayerItem(state),
    gameResult: getItem(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showAddPlayerItem}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultAddPlayer);