
class Round {
    static create = (game, gameType) => {
        switch (gameType) {
            case 'si':
                return {
                    name: '',
                    game_id: game._id,
                };
            default:
                return null;
        }
    }
}

export default Round;