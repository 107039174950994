import {store} from "../App";
import {getUser} from "../ducks/user";

export const BACKEND_URL = window.API_URL;

export const UNKNOWN_IMAGE_URL = '/image/no-image.png';
export const AUDIO_IMAGE_URL = '/image/audio.png';

export const getToken = () => {
    const state = store.getState();
    return getUser(state) !== null ? getUser(state).access_token : null;
};
