class Player {
    static create = (gameResult, gameType) => {
        switch (gameType) {
            case 'si':
                return {
                    name: '',
                    color: '#f00',
                    game_result_id: gameResult._id,
                };
            default:
                return null;
        }
    }
}

export default Player;