import React from 'react';
import {Card, CardHeader} from 'reactstrap';
import SvoyaIgraGameEditItem from "./svoyaigra/SvoyaIgraGameEditItem";
import ChgkGameEditItem from "./chgk/ChgkGameEditItem";
import BrainRingGameEditItem from "./brain/BrainRingGameEditItem";
import PropTypes from 'prop-types';
import {getEditGameItem} from "../ducks/tournamentView";
import {connect} from 'react-redux';

class GameEditItem extends React.Component {

    render() {
        let gameComponent = null;
        switch (this.props.item.game_type_id) {
            case 'si':
                gameComponent = <SvoyaIgraGameEditItem item={this.props.item}/>;
                break;
            case 'chgk':
                gameComponent = <ChgkGameEditItem item={this.props.item}/>;
                break;
            case 'br':
                gameComponent = <BrainRingGameEditItem item={this.props.item}/>;
                break;
            default:
        }

        return (
            <Card>
                <CardHeader>
                    {gameComponent}
                </CardHeader>
            </Card>
        );
    }
}

GameEditItem.propTypes = {
    item: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    item: getEditGameItem(state),
});

export default connect(mapStateToProps, null) (GameEditItem);
