import React from 'react';
import {Button} from 'reactstrap';
import {getAddQuestionItem, showAddQuestionItem} from "../../ducks/gameView";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SvoyaIgraQuestionEditItem from "./SvoyaIgraQuestionEditItem";
import PropTypes from 'prop-types';
import {TYPE_SI} from "../../models/GameType";

class SvoyaIgraAddQuestion extends React.Component {
    render() {
        const addItem = this.props.addItem;
        const category = this.props.category;
        if (addItem !== null && addItem.question_category_id === category._id) {
            return <SvoyaIgraQuestionEditItem item={addItem}/>
        }

        return (
            <Button color={'link'} className={'btn-block'}
                    onClick={() => this.props.showAddQuestionItem(category, TYPE_SI)}>
                Добавить вопрос
            </Button>
        )
    }
}

SvoyaIgraAddQuestion.propTypes = {
    category: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    addItem: getAddQuestionItem(state)
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showAddQuestionItem}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (SvoyaIgraAddQuestion);