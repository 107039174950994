import {takeEvery} from 'redux-saga/effects';
import {getUser, isAuthenticated as isUserAuthenticated} from "./user";
import io from 'socket.io-client';
import {store} from '../App';

import {
    ADD_QUESTION_RESULT,
    ADD_WRONG_QUESTION_RESULT,
    BLOCK_BUTTONS,
    HIDE_ADD_QUESTION_RESULT,
    SET_ACTIVE_QUESTION,
    SET_ACTIVE_ROUND,
    SHOW_ADD_QUESTION_RESULT,
    UNSET_ACTIVE_QUESTION,
    UNSET_ACTIVE_ROUND,
    VIEW_SUCCEED,
} from "./gameResultView";
import jwt from "jsonwebtoken";

// web socket


let socket;

//actions

const AUTH = 'igames/ws/AUTH';
const AUTH_SUCCEED = 'igames/ws/AUTH_SUCCEED';
const AUTH_ERROR = 'igames/ws/AUTH_ERROR';
const GET_CLIENTS = 'igames/ws/GET_CLIENTS';
const GET_CLIENTS_SUCCEED = 'igames/ws/GET_CLIENTS_SUCCEED';
const CONNECT_WEBSOCKET = 'igames/ws/CONNECT_WEBSOCKET';
const DISCONNECT_WEBSOCKET = 'images/ws/DISCONNECT_WEBSOCKET';


const initialState = {
    url: null,
    clients: [],
    authenticated: false,
    errors: [],
    loading: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case AUTH:
            return {
                ...state,
                authenticated: false,
                loading: true,
                errors: [],
            };
        case AUTH_SUCCEED:
            return {
                ...state,
                authenticated: true,
                loading: false,
                errors: [],
            };
        case AUTH_ERROR:
            return {
                ...state,
                authenticated: false,
                errors: action.payload,
                loading: false,
            };
        case GET_CLIENTS:
            return {
                ...state,
                clients: [],
                errors: [],
                loading: false,
            };
        case GET_CLIENTS_SUCCEED:
            return {
                ...state,
                clients: action.payload.clients,
                errors: [],
                loading: false,
            };
        case CONNECT_WEBSOCKET:
            return {
                ...state,
                url: action.payload,
            };
        case DISCONNECT_WEBSOCKET:
            return {
                ...state,
                url: action.payload,
            };
        default:
            return state;
    }
};

//selectors

export function getState(state) {
    return state.ws;
}

export function isAuthenticated(state) {
    return getState(state).authenticated;
}

export function isLoading(state) {
    return getState(state).loading;
}

export function getErrors(state) {
    return getState(state).errors;
}

export function getClients(state) {
    return getState(state).clients;
}

//action creators
export function auth(token) {
    return {
        type: AUTH,
        payload: {
            token: token,
        }
    }
}

export function authSucceed() {
    return {type: AUTH_SUCCEED}
}

export function authError(errors) {
    return {
        type: AUTH_ERROR,
        payload: errors
    }
}

export function getClientsTrigger() {
    return {type: GET_CLIENTS}
}

export function getClientSucceed(clients) {
    return {
        type: GET_CLIENTS_SUCCEED,
        payload: {
            clients: clients,
        }
    }
}

export function connectWebsocket() {
    return {type: CONNECT_WEBSOCKET,}
}

export function disconnectWebsocket() {
    return {
        type: DISCONNECT_WEBSOCKET,
    }
}

//sagas

function* emitAuth(action) {
    if (socket) {
        socket.emit('authenticate', action)
    }
}

function* emitGetClients(action) {
    if (socket) {
        console.log('sending get clients');
        socket.emit('getClients', action)
    }
}

function* connectWebsocketSaga() {
    const state = store.getState();
    console.log(state);
    if (!isUserAuthenticated(state)) {
        console.warn('Not connecting to web socket: user not logged in');
        return;
    }
    const accessToken = getUser(state).access_token;
    const decoded = jwt.decode(accessToken);
    console.log(decoded);
    const wsServerUrl = decoded.wsServer;

    if (!wsServerUrl) {
        console.warn('wsServerUrl not set up in token');
        return;
    }
    console.log("connect websocket to url ", wsServerUrl);
    socket = io(wsServerUrl, {
        transports: ['websocket'],
    });

    socket.on("connect", function () {
        console.log("Send authenticate msg");
        if (socket) {
            socket.emit('authenticate', {
                type: "igames/ws/AUTH",
                payload: {
                    token: accessToken,
                }
            })
        }
    });

    socket.on('gameEvent', function (data) {
        // Помечаем action как пришедший с другого клиента чтобы не отсылать его повторно
        console.log('receive message ', data);
        store.dispatch({
            ...data,
            remote: true,
        });
    });


    socket.on("buttonsEvent", function (data) {
        console.log('on buttonsEvent');
        // Помечаем action как пришедший с другого клиента чтобы не отсылать его повторно
        store.dispatch({
            ...data,
            remote: true,
        });
    });

}

function* disconnectWebsocketSaga() {
    if (!socket) {
        console.error("web socket not connected");
        return
    }
    socket.close();
    socket = null;
}

function* emitGameEvent(action) {
    if (!socket) {
        console.error("web socket not connected");
        return
    }
    // Пропускаем actions, которые пришли с других клиентов и не пытаемся их заново отправлять
    if (action.remote) {
        console.log("prevent send because action from remote ", action);
        return;
    }
    console.log("send action to ws ", action);
    if (socket) {
        socket.emit("gameEvent", action)
    }
}

export function* wsSaga() {
    yield takeEvery(AUTH, emitAuth);
    yield takeEvery(GET_CLIENTS, emitGetClients);
    yield takeEvery(CONNECT_WEBSOCKET, connectWebsocketSaga);
    yield takeEvery([DISCONNECT_WEBSOCKET], disconnectWebsocketSaga);
    yield takeEvery([
        VIEW_SUCCEED,
        SET_ACTIVE_QUESTION,
        UNSET_ACTIVE_QUESTION,
        SET_ACTIVE_ROUND,
        UNSET_ACTIVE_ROUND,
        ADD_QUESTION_RESULT,
        ADD_WRONG_QUESTION_RESULT,
        SHOW_ADD_QUESTION_RESULT,
        HIDE_ADD_QUESTION_RESULT,
        BLOCK_BUTTONS
    ], emitGameEvent);
}

