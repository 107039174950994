import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from 'reactstrap';
import SvoyaIgraPresenterScreenQuestion from "./SvoyaIgraPresenterScreenQuestion";

class SvoyaIgraPresenterScreenQuestionCategory extends Component {
    render() {
        const category = this.props.category;
        return (
            <Row noGutters={true} style={{height: this.props.height}}>
                <Col className={'col-4 p-1 h-100'}>
                    <Card className={'SvoyaIgra-game-result-question-category-cell justify-content-center align-items-center h-100'}>
                        <h3 className={'text-center'}>{category.name}</h3>
                    </Card>
                </Col>
                {category.questions.map((item) => <SvoyaIgraPresenterScreenQuestion key={item._id} question={item}/>)}
            </Row>
        );
    }
}

SvoyaIgraPresenterScreenQuestionCategory.propTypes = {
    category: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
};

export default SvoyaIgraPresenterScreenQuestionCategory;
