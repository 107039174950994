
const TEXT = 'text';
const IMAGE = 'image';
const AUDIO = 'audio';

class Question {
    static create = (questionCategory, gameType) => {
        switch (gameType) {
            case 'si':
                return {
                    question: '',
                    question_category_id: questionCategory._id,
                    answer: '',
                    question_type_id: TEXT,
                };
            default:
                return null;
        }
    }
}

export default Question;