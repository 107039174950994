import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addGame, editGame, hideAddGameItem, hideEditGameItem} from '../../ducks/tournamentView';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

class SvoyaIgraGameEditItem extends Component {
    constructor(props) {
        super(props);
        let item = props.item;
        item = {
            ...item,
            game_type_id: 'si',
        };
        this.state = {
            item: item
        }
    }

    setName(name) {
        this.setState((prevState) => ({
            ...prevState,
            item: {
                ...prevState.item,
                name: name,
            }
        }))
    }

    onSubmit(event) {
        const game = this.state.item;
        if (game._id !== undefined) {
            this.props.editGame(game);
        } else {
            this.props.addGame('si', game);
        }
        event.preventDefault();
    }

    onCancel() {
        const game = this.state.item;
        if (game._id !== undefined) {
            this.props.hideEditGameItem();
        } else {
            this.props.hideAddGameItem();
        }
    }
    
    render() {
        const item = this.state.item;
        return (
            <Form onSubmit={(event) => this.onSubmit(event)}>
                <InputGroup>
                    <Input
                        type={'text'}
                        placeholder={'Название игры'}
                        value={item.name}
                        onChange={(event) => this.setName(event.target.value)}
                    />
                    <InputGroupAddon addonType={'append'}>
                        <Button onClick={(event) => this.onSubmit(event)} color={'success'}>
                            <FontAwesomeIcon icon={faCheck}/>
                        </Button>
                        <Button onClick={() => this.onCancel()}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>

            </Form>
        );
    }
}

SvoyaIgraGameEditItem.propTypes = {
    item: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({addGame, editGame, hideEditGameItem, hideAddGameItem}, dispatch);
};

export default connect(null, mapDispatchToProps) (SvoyaIgraGameEditItem);
