
const TYPE_SI = 'si';
const TYPE_CHGK = 'chgk';
const TYPE_BR = 'br';

const available = {
    si: 'Своя игра',
    chgk: 'Что? Где? Когда?',
    br: 'Брейн Ринг',
};

const getName = (gameType) => {
    if (available[gameType] !== undefined) {
        return available[gameType];
    }
    return 'Неизвестная игра';
};

export {available, getName, TYPE_SI, TYPE_BR, TYPE_CHGK};