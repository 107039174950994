import React, {Component} from 'react';

class ChgkGameEditItem extends Component {
    
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

ChgkGameEditItem.propTypes = {};

export default ChgkGameEditItem;
