import {combineReducers} from 'redux';
import userReducer, {userSaga} from './user';
import {all} from 'redux-saga/effects';
import tournamentIndexReducer, {tournamentIndexSaga} from "./tournamentIndex";
import tournamentViewReducer, {tournamentViewSaga} from "./tournamentView";
import gameViewReducer, {gameViewSaga} from "./gameView";
import gameResultIndexReducer, {gameResultIndexSaga} from './gameResultIndex';
import gameResultViewReducer, {gameResultViewSaga} from './gameResultView';
import commonReducer from './common';
import wsReducer, {wsSaga} from "./ws";

export default combineReducers({
    user: userReducer,
    tournamentIndex: tournamentIndexReducer,
    tournamentView: tournamentViewReducer,
    gameView: gameViewReducer,
    gameResultIndex: gameResultIndexReducer,
    gameResultView: gameResultViewReducer,
    common: commonReducer,
    ws: wsReducer,
});

export function* rootSaga() {
    yield all([
        userSaga(), 
        tournamentIndexSaga(),
        tournamentViewSaga(),
        gameViewSaga(),
        gameResultIndexSaga(),
        gameResultViewSaga(),
        wsSaga(),
    ])
}
