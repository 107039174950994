import React, {Component} from 'react';
import {getItem, view} from "../ducks/gameResultView";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import SvoyaIgraGameUserScreen from "./svoyaigra/SvoyaIgraGameUserScreen";
import {setFullscreen, unsetFullscreen} from '../ducks/common';

class GameUserScreen extends Component {

    componentDidMount() {
        // const {gameId, gameResultId, gameType} = this.props.match.params;
        this.props.setFullscreen();
        // this.props.view(gameResultId, gameId, gameType);
    }

    componentWillUnmount() {
        this.props.unsetFullscreen();
    }

    render() {
        const {gameType} = this.props.match.params;
        if (this.props.item == null) {
            return (
                <h2>Загрузка данных</h2>
            );
        }

        return this.renderView(this.props.item, gameType);
    }

    renderView = (item, gameType) => {
        return <SvoyaIgraGameUserScreen item={item} gameType={gameType}/>;
        // switch (gameType) {
        //     case TYPE_SI:
        //         return <SvoyaIgraGameUserScreen item={item} gameType={gameType}/>;
        //     default:
        //         return null;
        // }
    };
}

GameUserScreen.propTypes = {};

const mapStateToProps = (state) => ({
    item: getItem(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        view,
        setFullscreen,
        unsetFullscreen,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GameUserScreen);

