import React from 'react';
import ListHeader from "./ListHeader";
import PropTypes from 'prop-types';

const AddItemList = (props) => {
    return (
        <>
            <ListHeader header={props.header}
                        addButtonText={props.addButtonText}
                        onAddButtonClick={() => props.onAddButtonClick()}
                        displayAddButton={props.displayAddButton}
            />
            {props.displayAddItem &&
            props.renderAddItem()
            }
            {props.data && props.data.map((item => props.renderItem(item)))}
        </>
    );
};


AddItemList.propTypes = {
    header: PropTypes.string,
    addButtonText: PropTypes.string,
    onAddButtonClick: PropTypes.func,
    renderAddItem: PropTypes.func,
    renderItem: PropTypes.func,
    data: PropTypes.array,
    displayAddItem: PropTypes.bool,
    displayAddButton: PropTypes.bool,
};

AddItemList.defaultProps = {
    displayAddButton: true,
};

export default AddItemList;

