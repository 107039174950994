import * as ApiGame from '../api/game';
import * as ApiRound from '../api/round';
import * as ApiQuestionCategory from '../api/questionCategory';
import * as ApiQuestion from '../api/question';
import * as ApiUpload from '../api/upload';

import {call, put, select, takeLatest} from 'redux-saga/effects';
import Round from '../models/Round';
import QuestionCategory from '../models/QuestionCategory';
import Question from '../models/Question';
import _ from 'lodash';
import {TYPE_AUDIO, TYPE_IMAGE, TYPE_VIDEO} from "../models/SvoyaIgraQuestionType";

//actions

const VIEW = "igames/gameView/VIEW";
const VIEW_SUCCEED = "igames/gameView/VIEW_SUCCEED";

const ADD_ROUND = 'igames/gameView/ADD_ROUND';
const EDIT_ROUND = 'igames/gameView/EDIT_ROUND';
const DELETE_ROUND = 'igames/gameView/DELETE_ROUND';

const ADD_QUESTION_CATEGORY = 'igames/gameView/ADD_QUESTION_CATEGORY';
const EDIT_QUESTION_CATEGORY = 'igames/gameView/EDIT_QUESTION_CATEGORY';
const DELETE_QUESTION_CATEGORY = 'igames/gameView/DELETE_QUESTION_CATEGORY';

const ADD_QUESTION = 'igames/gameView/ADD_QUESTION';
const EDIT_QUESTION = 'igames/gameView/EDIT_QUESTION';
const DELETE_QUESTION = 'igames/gameView/DELETE_QUESTION';

const ERROR = 'igames/gameView/ERROR';

const SHOW_ADD_ROUND = 'igames/gameView/SHOW_ADD_ROUND';
const HIDE_ADD_ROUND = 'igames/gameView/HIDE_ADD_ROUND';
const SHOW_EDIT_ROUND = 'igames/gameView/SHOW_EDIT_ROUND';
const HIDE_EDIT_ROUND = 'igames/gameView/HIDE_EDIT_ROUND';

const SHOW_ADD_QUESTION_CATEGORY = 'igames/gameView/SHOW_ADD_QUESTION_CATEGORY';
const HIDE_ADD_QUESTION_CATEGORY = 'igames/gameView/HIDE_ADD_QUESTION_CATEGORY';
const SHOW_EDIT_QUESTION_CATEGORY = 'igames/gameView/SHOW_EDIT_QUESTION_CATEGORY';
const HIDE_EDIT_QUESTION_CATEGORY = 'igames/gameView/HIDE_EDIT_QUESTION_CATEGORY';

const SHOW_ADD_QUESTION = 'igames/gameView/SHOW_ADD_QUESTION';
const HIDE_ADD_QUESTION = 'igames/gameView/HIDE_ADD_QUESTION';
const SHOW_EDIT_QUESTION = 'igames/gameView/SHOW_EDIT_QUESTION';
const HIDE_EDIT_QUESTION = 'igames/gameView/HIDE_EDIT_QUESTION';

const SET_ACTIVE_ROUND = 'igames/gameView/SET_ACTIVE_ROUND';
const UNSET_ACTIVE_ROUND = 'igames/gameView/UNSET_ACTIVE_ROUND';

const initialState = {
    item: null,
    errors: [],
    loading: false,

    addQuestionCategoryItem: null,
    editQuestionCategoryItem: null,

    addRoundItem: null,
    editRoundItem: null,
    activeRoundId: null,

    addQuestionItem: null,
    editQuestionItem: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ERROR:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case SHOW_ADD_ROUND:
            console.log('show add round called payload = ' + JSON.stringify(action.payload));
            return {
                ...state,
                addRoundItem: action.payload,
            };
        case HIDE_ADD_ROUND:
            return {
                ...state,
                addRoundItem: null,
            };
        case SHOW_EDIT_ROUND:
            return {
                ...state,
                editRoundItem: action.payload,
            };
        case HIDE_EDIT_ROUND:
            return {
                ...state,
                editRoundItem: null,
            };
        case SHOW_ADD_QUESTION_CATEGORY:
            return {
                ...state,
                addQuestionCategoryItem: action.payload,
            };
        case HIDE_ADD_QUESTION_CATEGORY:
            return {
                ...state,
                addQuestionCategoryItem: null,
            };
        case SHOW_EDIT_QUESTION_CATEGORY:
            return {
                ...state,
                editQuestionCategoryItem: action.payload,
            };
        case HIDE_EDIT_QUESTION_CATEGORY:
            return {
                ...state,
                editQuestionCategoryItem: null,
            };
        case SHOW_ADD_QUESTION:
            console.log('payload = ' + JSON.stringify(action.payload));
            return {
                ...state,
                addQuestionItem: action.payload,
            };
        case HIDE_ADD_QUESTION:
            return {
                ...state,
                addQuestionItem: null,
            };
        case SHOW_EDIT_QUESTION:
            return {
                ...state,
                editQuestionItem: action.payload,
            };
        case HIDE_EDIT_QUESTION:
            return {
                ...state,
                editQuestionItem: null,
            };
        case VIEW:
            return {
                ...state,
                loading: true,
            };
        case VIEW_SUCCEED:
            return {
                ...state,
                item: action.payload,
                loading: false,
                errors: [],
            };
        case SET_ACTIVE_ROUND:
            return {
                ...state,
                activeRoundId: action.payload,
            };
        case UNSET_ACTIVE_ROUND:
            return {
                ...state,
                activeRoundId: null,
            };
        default:
            return state;
    }
};

//selectors

export function getState(state) {
    return state.gameView;
}

export function getItem(state) {
    return getState(state).item;
}

export function getLoading(state) {
    return getState(state).loading;
}

export function getErrors(state) {
    return getState(state).errors;
}

export function getAddRoundItem(state) {
    return getState(state).addRoundItem;
}

export function getEditRoundItem(state) {
    return getState(state).editRoundItem;
}

export function getAddQuestionCategoryItem(state) {
    return getState(state).addQuestionCategoryItem;
}

export function getEditQuestionCategoryItem(state) {
    return getState(state).editQuestionCategoryItem;
}

export function getAddQuestionItem(state) {
    return getState(state).addQuestionItem;
}

export function getEditQuestionItem(state) {
    return getState(state).editQuestionItem;
}

export function getActiveRoundId(state) {
    return getState(state).activeRoundId;
}

export function getActiveRound(state) {
    let activeId = getActiveRoundId(state);
    if (activeId == null) {
        const item = getItem(state);
        if (item.rounds.length > 0) {
            return getItem(state).rounds[0];
        }
    } else {
        return _.find(getItem(state).rounds, (item) => item._id === activeId);
    }
}

//action creators

export function viewSucceed(tournament) {
    return {
        type: VIEW_SUCCEED,
        payload: tournament
    }
}

export function view(gameId, gameType) {
    return {
        type: VIEW,
        payload: {gameId, gameType},
    }
}

export function addRound(round, gameType) {
    return {
        type: ADD_ROUND,
        payload: {round, gameType}
    };
}

export function editRound(round, gameType) {
    return {
        type: EDIT_ROUND,
        payload: {round, gameType},
    }
}

export function deleteRound(round, gameType) {
    return {
        type: DELETE_ROUND,
        payload: {round, gameType},
    }
}


export function error(errors) {
    return {
        type: ERROR,
        payload: errors
    }
}

export function showAddRoundItem(game, gameType) {
    const round = Round.create(game, gameType);
    return {
        type: SHOW_ADD_ROUND,
        payload: round,
    }
}

export function hideAddRoundItem() {
    return {
        type: HIDE_ADD_ROUND
    }
}

export function showEditRoundItem(round) {
    return {
        type: SHOW_EDIT_ROUND,
        payload: round,
    }
}

export function hideEditRoundItem() {
    return {type: HIDE_EDIT_ROUND};
}

export function setActiveRound(id) {
    return {type: SET_ACTIVE_ROUND, payload: id};
}

export function unsetActiveRound() {
    return {type: UNSET_ACTIVE_ROUND}
}

// question category action creators

export function addQuestionCategory(category, gameType) {
    return {
        type: ADD_QUESTION_CATEGORY,
        payload: {category, gameType}
    };
}

export function editQuestionCategory(category, gameType) {
    return {
        type: EDIT_QUESTION_CATEGORY,
        payload: {category, gameType},
    }
}

export function deleteQuestionCategory(category, gameType) {
    return {
        type: DELETE_QUESTION_CATEGORY,
        payload: {category, gameType},
    }
}

export function showAddQuestionCategoryItem(round, gameType) {
    console.log('questionCategory = ' + JSON.stringify(round));
    const category = QuestionCategory.create(round, gameType);
    return {
        type: SHOW_ADD_QUESTION_CATEGORY,
        payload: category,
    }
}

export function hideAddQuestionCategoryItem() {
    return {
        type: HIDE_ADD_QUESTION_CATEGORY,
    }
}

export function showEditQuestionCategoryItem(category) {
    return {
        type: SHOW_EDIT_QUESTION_CATEGORY,
        payload: category,
    }
}

export function hideEditQuestionCategoryItem() {
    return {type: HIDE_EDIT_QUESTION_CATEGORY};
}

// question action creators

export function addQuestion(question, uploadFile, gameType) {
    return {
        type: ADD_QUESTION,
        payload: {question, uploadFile, gameType}
    };
}

export function editQuestion(question, uploadFile, gameType) {
    return {
        type: EDIT_QUESTION,
        payload: {question, uploadFile, gameType},
    }
}

export function deleteQuestion(question, gameType) {
    return {
        type: DELETE_QUESTION,
        payload: {question, gameType},
    }
}

export function showAddQuestionItem(category, gameType) {
    const question = Question.create(category, gameType);
    return {
        type: SHOW_ADD_QUESTION,
        payload: question,
    }
}

export function hideAddQuestionItem() {
    return {
        type: HIDE_ADD_QUESTION,
    }
}

export function showEditQuestionItem(question) {
    return {
        type: SHOW_EDIT_QUESTION,
        payload: question,
    }
}

export function hideEditQuestionItem() {
    return {type: HIDE_EDIT_QUESTION};
}


//sagas

function* fetchView(action) {
    try {
        const response = yield call(ApiGame.view,
            action.payload.gameId,
            action.payload.gameType,
        );
        if (response.status === true) {
            const game = response.payload;
            yield put(viewSucceed(game));
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchAddRound(action) {
    try {
        const response = yield call(ApiRound.add,
            action.payload.round,
            action.payload.gameType,
        );
        if (response.status === true) {
            const game = yield select(getItem);
            yield put(view(game._id, action.payload.gameType));
            yield put(hideAddRoundItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchEditRound(action) {
    const {round, gameType} = action.payload;
    try {
        const response = yield call(ApiRound.edit, round, gameType);
        if (response.status === true) {
            const game = yield select(getItem);
            yield put(view(game._id, gameType));
            yield put(hideEditRoundItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchDeleteRound(action) {
    const {round, gameType} = action.payload;
    try {
        yield call(ApiRound.del, round, gameType);
        const game = yield select(getItem);
        yield put(view(game._id, gameType));
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchAddQuestionCategory(action) {
    const {category, gameType} = action.payload;
    try {
        const response = yield call(ApiQuestionCategory.add, category, gameType);
        if (response.status === true) {
            const game = yield select(getItem);
            yield put(view(game._id, action.payload.gameType));
            yield put(hideAddQuestionCategoryItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchEditQuestionCategory(action) {
    const {category, gameType} = action.payload;
    try {
        const response = yield call(ApiQuestionCategory.edit, category, gameType);
        if (response.status === true) {
            const game = yield select(getItem);
            yield put(view(game._id, gameType));
            yield put(hideEditQuestionCategoryItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchDeleteQuestionCategory(action) {
    const {category, gameType} = action.payload;
    try {
        yield call(ApiQuestionCategory.del, category, gameType);
        const game = yield select(getItem);
        yield put(view(game._id, gameType));
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchAddQuestion(action) {
    const {uploadFile, gameType} = action.payload;
    let question = action.payload.question;
    try {
        if (uploadFile !== null) {
            const uploadResponse = yield call(fetchUpload,
                uploadFile,
                question.question_type_id);
            if (uploadResponse.status === true) {
                question = {
                    ...question,
                    url: uploadResponse.payload.path
                }
            } else {
                yield put(error(uploadResponse.errors));
                return;
            }
        }
        const response = yield call(ApiQuestion.add, question, gameType);
        if (response.status === true) {
            const game = yield select(getItem);
            yield put(view(game._id, action.payload.gameType));
            yield put(hideAddQuestionItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchEditQuestion(action) {
    const {uploadFile, gameType} = action.payload;
    let question = action.payload.question;
    try {
        if (uploadFile !== null) {
            const uploadResponse = yield call(fetchUpload,
                uploadFile,
                question.question_type_id);
            if (uploadResponse.status === true) {
                question = {
                    ...question,
                    url: uploadResponse.payload.path
                };
            } else {
                yield put(error(uploadResponse.errors));
                return;
            }
        }
        const response = yield call(ApiQuestion.edit, question, gameType);
        if (response.status === true) {
            const game = yield select(getItem);
            yield put(view(game._id, gameType));
            yield put(hideEditQuestionItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

async function fetchUpload(file, type) {
    switch (type) {
        case TYPE_IMAGE:
            return ApiUpload.image(file);
        case TYPE_AUDIO:
            return ApiUpload.audio(file);
        case TYPE_VIDEO:
            return ApiUpload.video(file);
        default:
            return null;
    }
}

function* fetchDeleteQuestion(action) {
    const {question, gameType} = action.payload;
    try {
        yield call(ApiQuestion.del, question, gameType);
        const game = yield select(getItem);
        yield put(view(game._id, gameType));
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

export function* gameViewSaga() {
    yield takeLatest(VIEW, fetchView);

    yield takeLatest(ADD_ROUND, fetchAddRound);
    yield takeLatest(EDIT_ROUND, fetchEditRound);
    yield takeLatest(DELETE_ROUND, fetchDeleteRound);

    yield takeLatest(ADD_QUESTION_CATEGORY, fetchAddQuestionCategory);
    yield takeLatest(EDIT_QUESTION_CATEGORY, fetchEditQuestionCategory);
    yield takeLatest(DELETE_QUESTION_CATEGORY, fetchDeleteQuestionCategory);

    yield takeLatest(ADD_QUESTION, fetchAddQuestion);
    yield takeLatest(EDIT_QUESTION, fetchEditQuestion);
    yield takeLatest(DELETE_QUESTION, fetchDeleteQuestion);
}

