import React, {Component} from 'react';
import {getActiveQuestion, getActiveRound, getItem} from "../../ducks/gameResultView";
import {connect} from "react-redux";
import SvoyaIgraUserScreenQuestionTable from "./userScreen/SvoyaIgraUserScreenQuestionTable";
import SvoyaIgraUserScreenActiveQuestion from "./userScreen/SvoyaIgraUserScreenActiveQuestion";
import SvoyaIgraUserScreenPlayersPanel from "./userScreen/SvoyaIgraUserScreenPlayersPanel";

class SvoyaIgraGameUserScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: 0,
            windowHeight: 0,
        }
    }

    updateDimensions() {
        this.setState((prevState, props) => ({
            ...prevState,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        }))
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        if (this.props.activeQuestion !== null) {
            return (
                <SvoyaIgraUserScreenActiveQuestion
                    question={this.props.activeQuestion}
                    width={this.state.windowWidth}
                    height={this.state.windowHeight}
                />
            );
        }
        return (
            <>
                <SvoyaIgraUserScreenQuestionTable
                    round={this.props.activeRound}
                    width={this.state.windowWidth}
                    height={this.state.windowHeight - 150}
                />
                <SvoyaIgraUserScreenPlayersPanel players={this.props.item.players}/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    item: getItem(state),
    activeRound: getActiveRound(state),
    activeQuestion: getActiveQuestion(state),
});

export default connect(mapStateToProps, null)(SvoyaIgraGameUserScreen);
