import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TYPE_AUDIO, TYPE_IMAGE, TYPE_TEXT, TYPE_VIDEO} from "../../../models/SvoyaIgraQuestionType";
import {AUDIO_IMAGE_URL, UNKNOWN_IMAGE_URL} from "../../../api/common";
import {getAddQuestionResultItem, getItem} from "../../../ducks/gameResultView";
import {connect} from "react-redux";
import * as _ from 'lodash';
import SvolyaIgraUserScreenActivePlayerView from "./SvolyaIgraUserScreenActivePlayerView";
import ReactPlayer from "react-player";

class SvoyaIgraUserScreenActiveQuestion extends Component {
    render() {
        let question = null;
        switch (this.props.question.question_type_id) {
            case TYPE_TEXT:
                question = this.renderTextQuestion();
                break;
            case TYPE_IMAGE:
                question = this.renderImageQuestion();
                break;
            case TYPE_AUDIO:
                question = this.renderAudioQuestion();
                break;
            case TYPE_VIDEO:
                question = this.renderVideoQuestion();
                break;
        }

        let activePlayer = null;
        if (this.props.addQuestionResultItem) {
            activePlayer = _.find(this.props.players, pl => pl._id === this.props.addQuestionResultItem.player_id);
        }

        return (
            <SvolyaIgraUserScreenActivePlayerView activePlayer={activePlayer} width={this.props.width} height={this.props.height}>
                {question}
            </SvolyaIgraUserScreenActivePlayerView>
        );
    }

    renderTextQuestion() {
        return (
            <>
                <div className="jumbotron h-100 d-flex justify-content-center align-items-center SvoyaIgra-background">
                    <h1 className="display-3 my-auto SvoyaIgra-active-question">{this.props.question.question}</h1>
                </div>
            </>
        );
    }

    renderImageQuestion() {
        const url = this.props.question.url || UNKNOWN_IMAGE_URL;
        return(
            <div className="jumbotron h-100 d-flex justify-content-center align-items-center SvoyaIgra-background">
                <img alt={'Изображение вопроса'} src={url} className={'img-fluid'}/>
            </div>
        )
    }

    renderAudioQuestion() {
        return (
            <div className="jumbotron h-100 d-flex justify-content-center align-items-center SvoyaIgra-background">
                <img alt={'Изображение вопроса'} src={AUDIO_IMAGE_URL} className={'img-fluid'}/>
            </div>
        );
    }

    renderVideoQuestion() {
        return (
            <div className="jumbotron h-100 d-flex justify-content-center align-items-center SvoyaIgra-background">
                <ReactPlayer url={this.props.question.url} width={window.innerWidth - 200} height={window.innerHeight - 200} playing={true}/>
            </div>
        );
    }
}

SvoyaIgraUserScreenActiveQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    players: PropTypes.array.isRequired,
    addQuestionResultItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
    players: getItem(state).players,
    addQuestionResultItem: getAddQuestionResultItem(state),
});

export default connect(mapStateToProps, null)(SvoyaIgraUserScreenActiveQuestion);
