import React from "react";
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import reducer, {rootSaga} from './ducks/index';
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import {PersistGate} from 'redux-persist/integration/react';
import Router from './components/Router';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const App = (props) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
           <Router/>
        </PersistGate>
    </Provider>
);

export default App;
export {store};
