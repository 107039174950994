import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import PropTypes from 'prop-types';

class DeleteButton extends React.Component {

    onDelete = (event) => {
        const result = window.confirm('Вы действительно хотите удалить?');
        if (result) {
            this.props.onDelete(event);
        }
    };

    render() {
        return (
            <Button color={'link'}
                    onClick={(event) => this.onDelete(event)}>
                <FontAwesomeIcon icon={faTrash}/>
            </Button>
        );
    }
}

DeleteButton.propTypes = {
    onDelete: PropTypes.func.isRequired,
};

export default DeleteButton;