import React from 'react';
import {
    del,
    getAddItem,
    getEditItem,
    getErrors,
    getItems,
    index,
    showAddItem,
    showEditItem,
} from "../ducks/tournamentIndex";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {renderErrors} from "./common";
import TournamentAddItem from './TournamentEditItem';
import AddItemList from "./AddItemList";
import TournamentItem from './TournamentItem';


class TournamentIndex extends React.Component {

    componentDidMount() {
        this.props.index();
    }

    isAddItemDisplayed = () => this.props.addItem !== null;

    isEditItemDisplayed = () => this.props.editItem !== null;

    render() {
        return (
            <>
                {renderErrors(this.props.errors)}

                <AddItemList
                    header={'Список турниров'}
                    onAddButtonClick={() => this.props.showAddItem()}
                    addButtonText={'Добавить'}
                    renderAddItem={() => <TournamentAddItem item={this.props.addItem}/>}
                    renderItem={(item) => <TournamentItem key={item._id} item={item}/>}
                    displayAddButton={!this.isAddItemDisplayed()}
                    displayAddItem={this.isAddItemDisplayed()}
                    data={this.props.items}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        items: getItems(state),
        errors: getErrors(state),
        addItem: getAddItem(state),
        editItem: getEditItem(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({index, showAddItem, showEditItem, del}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps) (TournamentIndex);