import React from 'react';
import {Button} from 'reactstrap';
import {getAddQuestionCategoryItem, showAddQuestionCategoryItem} from "../../ducks/gameView";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SvoyaIgraQuestionCategoryEditItem from "./SvoyaIgraQuestionCategoryEditItem";
import PropTypes from 'prop-types';
import {TYPE_SI} from "../../models/GameType";

class SvoyaIgraAddQuestionCategory extends React.Component {
    render() {
        const addItem = this.props.addItem;
        const round = this.props.round;
        if (addItem !== null) {
            return <SvoyaIgraQuestionCategoryEditItem item={addItem}/>
        }

        return (
            <Button className={'btn btn-primary btn-block'}
                    onClick={() => this.props.showAddQuestionCategoryItem(round, TYPE_SI)}>
                Добавить категорию вопроса
            </Button>
        )
    }
}

SvoyaIgraAddQuestionCategory.propTypes = {
    round: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    addItem: getAddQuestionCategoryItem(state)
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showAddQuestionCategoryItem}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (SvoyaIgraAddQuestionCategory);