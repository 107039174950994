import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap'

class SvoyaIgraPresenterScreenPlayersPanel extends Component {
    render() {
        return (
            <Row style={{height: 150, overflow: 'hidden'}} className={'p-1'} noGutters={true}>
                {this.props.players.map((player) => this.renderPlayerItem(player))}
            </Row>
        );
    }

    renderPlayerItem = (player) => (
        <Col className={'h-100'} style={{backgroundColor: player.color, color: 'white'}}>
            <div className="w-100 justify-content-center align-items-center d-flex"
                 ref={(el) => {
                     if (el) {
                         el.style.setProperty('height', '60%', 'important');
                     }
                 }}>
                <h1 className={'display-3'}>{player.score}</h1>
            </div>
            <div className="w-100 justify-content-center align-items-center d-flex"
                 ref={(el) => {
                     if (el) {
                         el.style.setProperty('height', '40%', 'important');
                     }
                 }}>
                <h1 className={'display-5'}>{player.name}</h1>
            </div>

        </Col>
    );
}

SvoyaIgraPresenterScreenPlayersPanel.propTypes = {
    players: PropTypes.array.isRequired,
};

export default SvoyaIgraPresenterScreenPlayersPanel;
