import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, Col} from 'reactstrap';
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import {getAnsweredQuestionIds, setActiveQuestion} from '../../../ducks/gameResultView'

class SvoyaIgraUserScreenQuestion extends Component {

    isAnswered() {
        return this.props.answeredQuestionIds.has(this.props.question._id);
    }

    render() {
        const questionCellClass = this.isAnswered()
            ? 'SvoyaIgra-game-result-question-answer-cell h-100'
            : 'SvoyaIgra-game-result-question-cell h-100';
        const question = this.props.question;
        return (
            <Col className={'p-1'}>
                <Card className={`${questionCellClass} justify-content-center align-items-center h-100`}>
                    <h1 className={'text-center'}>{question.price}</h1>
                </Card>
            </Col>
        );
    }
}

SvoyaIgraUserScreenQuestion.propTypes = {
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    answeredQuestionIds: getAnsweredQuestionIds(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setActiveQuestion: setActiveQuestion
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraUserScreenQuestion);
