import {BACKEND_URL, getToken} from "./common";

async function login({username, password}) {
    const data = {
        username,
        password,
    };

    const url = `${BACKEND_URL}/v1/user/login`;

    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        }
    });
    response = response.json();
    return response;
}

async function list() {

    const url = `${BACKEND_URL}/v1/user`;

    let response = await fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

export {login, list}
