import React from 'react';
import {Button} from 'reactstrap';
import {getAddRoundItem, showAddRoundItem} from "../../ducks/gameView";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SvoyaIgraRoundEditItem from "./SvoyaIgraRoundEditItem";
import PropTypes from 'prop-types';
import {TYPE_SI} from "../../models/GameType";

class SvoyaIgraAddRound extends React.Component {
    render() {
        const addItem = this.props.addItem;
        const game = this.props.game;
        if (addItem !== null) {
            return <SvoyaIgraRoundEditItem item={addItem}/>
        }

        return (
            <Button className={'btn btn-primary btn-block'}
                    onClick={() => this.props.showAddRoundItem(game, TYPE_SI)}>
                Добавить раунд
            </Button>
        )
    }
}

SvoyaIgraAddRound.propTypes = {
    game: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    addItem: getAddRoundItem(state)
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showAddRoundItem}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (SvoyaIgraAddRound);