import React from 'react';
import {blockButtons, getErrors, getItem, view} from '../ducks/gameResultView';
import {getItem as getTournamentItem} from '../ducks/tournamentView';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Breadcrumb, BreadcrumbItem, Button, Col, Row,} from 'reactstrap';
import {Link} from 'react-router-dom';
import {TYPE_SI} from "../models/GameType";
import SvoyaIgraGameResultView from "./svoyaigra/gameResult/SvoyaIgraGameResultView";
import {renderErrors} from "./common";
import {getClientsTrigger} from "../ducks/ws";
import {list as getUserList} from "../ducks/user";


class GameResultView extends React.Component {

    componentDidMount() {
        this.sync();
    }

    sync() {
        const {gameId, gameResultId, gameType} = this.props.match.params;
        this.props.view(gameResultId, gameId, gameType);
        this.props.getClientsTrigger();
        this.props.getUserList();
    }

    renderView = (item, gameType) => {
        switch (gameType) {
            case TYPE_SI:
                return <SvoyaIgraGameResultView item={item} gameType={gameType}/>;
            default:
                return null;
        }
    };

    render() {
        const item = this.props.item;
        const game = this.props.game;
        const tournament = this.props.tournament;
        const {gameId, gameResultId, gameType} = this.props.match.params;
        if (item === null) {
            return <h2>Загрузка данных</h2>
        }

        return (
            <>
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/'}>Список турниров</Link></BreadcrumbItem>
                    <BreadcrumbItem>
                        {tournament !== null
                        &&
                        <Link to={`/tournament/${tournament._id}`}>
                            {tournament.name}
                        </Link>
                        }
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        <Link to={`/game/${gameType}/${gameId}`}>
                            {item.game.name}
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        <Link to={`/game-result/${gameType}/${gameId}`}>
                            Прохождения
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        <Link to={`/game/${gameType}/${gameId}`}>
                            {item.name}
                        </Link>
                    </BreadcrumbItem>
                </Breadcrumb>
                {renderErrors(this.props.errors)}
                <Row className={'m-2'}>
                    <Col sm={3}>
                        <Button className={'float-left'} color={'info'} onClick={() => this.sync()}>Синхронизировать</Button>
                    </Col>
                    <Col sm={3}>
                        <Button className={'float-right'} color={'info'} onClick={() => this.props.blockButtons()}>Блокировать
                            кнопки</Button>
                    </Col>
                </Row>
                {this.renderView(item, gameType)}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    item: getItem(state),
    tournament: getTournamentItem(state),
    errors: getErrors(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        view,
        blockButtons,
        getClientsTrigger,
        getUserList,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GameResultView);
