import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'reactstrap';
import {
    addQuestionResult,
    getActiveQuestion,
    getAddQuestionResultItem,
    getItem,
    showAddQuestionResultItem
} from '../../../ducks/gameResultView';
import QuestionResult from "../../../models/QuestionResult";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import SvoyaIgraGameResultModalAnswerPanel from "./SvoyaIgraGameResultModalAnswerPanel";

class SvoyaIgraGameResultModalPlayersPanel extends Component {

    nobodyAnswered() {
        const questionResult = QuestionResult.create(this.props.gameResult, this.props.question, null, this.props.gameType);
        this.props.addQuestionResult(questionResult, this.props.gameType);
    }

    playerAnswered(playerIndex) {
        this.props.showAddQuestionResultItem(playerIndex, this.props.gameType)
    }

    render() {
        if (this.props.addQuestionResultItem) {
            return <SvoyaIgraGameResultModalAnswerPanel
                gameType={this.props.gameType}
                addQuestionResultItem={this.props.addQuestionResultItem}/>
        }

        return (
            <Row>
                {this.props.gameResult.players.map((player, index) => <Col key={player._id}>
                    <Button className={'m-1'} color={'primary'} onClick={() => this.playerAnswered(index)}>
                            {player.name}<span className={'badge'} style={{
                            marginLeft: 5,
                            backgroundColor: player.color
                        }}>&nbsp;&nbsp;</span></Button>
                    </Col>
                )}
                <Col>
                    <Button color={'primary m-1'} onClick={() => this.nobodyAnswered()}>
                        Никто</Button>
                </Col>
            </Row>
        );
    }
}

SvoyaIgraGameResultModalPlayersPanel.propTypes = {
    gameResult: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
    gameType: PropTypes.string.isRequired,
    addQuestionResultItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
    gameResult: getItem(state),
    question: getActiveQuestion(state),
    addQuestionResultItem: getAddQuestionResultItem(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showAddQuestionResultItem,
    addQuestionResult
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultModalPlayersPanel);
