import React, {Component} from 'react';
import PropTypes from "prop-types";

class PlayerScreenActivePlayerView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userAnswered: false,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps.player);
        console.log(nextProps.addQuestionResultItem);
        if (nextProps.player && nextProps.addQuestionResultItem && nextProps.player._id === nextProps.addQuestionResultItem.player_id) {
            this.setUserAnswered(true);
        } else {
            this.setUserAnswered(false);
        }
    }

    setUserAnswered(flag) {
        console.log('user answered = ', flag);
        this.setState(prevState => ({...prevState, userAnswered: flag}));
    }

    render() {
        const styles = {
            width: this.props.width,
            height: this.props.height,
            overflow: 'hidden',
            backgroundColor: 'mediumslateblue',
        };
        if (this.state.userAnswered) {
            styles.backgroundColor = this.props.color;
        } else if (this.props.player) {
            styles.backgroundColor = this.props.player.color;
        }
        return (
            <div style={styles} className={'justify-content-center'}>
                <div style={{padding: this.props.boundaryWidth, width: this.props.width, height: this.props.height}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

PlayerScreenActivePlayerView.propTypes = {
    boundaryWidth: PropTypes.number.isRequired,
    player: PropTypes.object.isRequired,
    addQuestionResultItem: PropTypes.object.isRequired,
    userAnswered: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
};

export default PlayerScreenActivePlayerView;
