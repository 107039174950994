import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, Col} from 'reactstrap';
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import {
    editQuestionResult,
    getAnsweredQuestionIds,
    setActiveQuestion,
    showEditQuestionResultItem
} from '../../../ducks/gameResultView'

class SvoyaIgraGameResultQuestion extends Component {

    isAnswered() {
        return this.props.answeredQuestionIds.has(this.props.question._id);
    }

    render() {
        const questionCellClass = this.isAnswered()
            ? 'SvoyaIgra-game-result-question-answer-cell'
            : 'SvoyaIgra-game-result-question-cell';
        const question = this.props.question;
        return (
            <Col className={'m-1'}>
                <Card
                    className={`${questionCellClass} justify-content-center align-items-center`}
                    onClick={
                        () => {
                            if (!this.isAnswered()) {
                                this.props.setActiveQuestion(this.props.question)
                            } else {
                                this.props.showEditQuestionResult(this.props.question);
                            }
                        }
                    }
                >
                    <h1 className={'text-center'}>{question.price}</h1>
                </Card>
            </Col>
        );
    }
}

SvoyaIgraGameResultQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    showEditQuestionResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    answeredQuestionIds: getAnsweredQuestionIds(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setActiveQuestion: setActiveQuestion,
    showEditQuestionResult: showEditQuestionResultItem,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultQuestion);
