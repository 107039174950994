import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Button, CardBody} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {
    deleteQuestionResult,
    editQuestionResult,
    getActiveQuestion,
    getAddQuestionResultItem,
    getEditQuestionResultItem,
    getItem, hideEditQuestionResultItem,
    unsetActiveQuestion
} from "../../../ducks/gameResultView";

class SvoyaIgraGameResultAnsweredQuestionModal extends Component {

    isModalDisplayed() {
        return this.props.editQuestionResultItem !== undefined && this.props.editQuestionResultItem !== null;
    }

    getPlayerNameById(playerId) {
        const player = this.props.gameResult.players.find(pl => pl._id === playerId);
        return player ? player.name : "Никто";
    }

    getPlayerColorById(playerId) {
        const player = this.props.gameResult.players.find(pl => pl._id === playerId);
        return player ? player.color : "#fff";
    }

    renderQuestionResults() {
        const question = this.props.editQuestionResultItem;
        if (!this.props.gameResult || !this.props.gameResult.questionResults) {
            return null;
        }
        const playerAnswers = this.props.gameResult.questionResults.filter(qr => qr.question_id === question._id);
        if (playerAnswers.length === 0) {
            return <CardBody>Нет ответов</CardBody>
        }
        return playerAnswers.map((playerAnswer, i) => <Card key={"" + i}>
            <CardBody>
                <Row>
                    <Col><span className={'badge'} style={{
                        marginRight: 5,
                        backgroundColor: this.getPlayerColorById(playerAnswer.player_id)
                    }}>&nbsp;&nbsp;</span>{this.getPlayerNameById(playerAnswer.player_id)}</Col>
                    <Col>{playerAnswer.cost}</Col>
                    <Col><Button onClick={() => this.props.deleteQuestionResult(playerAnswer, 'si')} className={"float-right"}>Удалить</Button></Col>
                </Row>
            </CardBody>
        </Card>)
    }

    render() {
        const question = this.props.editQuestionResultItem;

        if (!this.isModalDisplayed()) {
            return null;
        }
        return (
            <Modal isOpen={true}>
                <ModalHeader toggle={() => this.props.hideEditQuestionResultItem()}>
                    {question.price} - Управление ответами
                </ModalHeader>
                <ModalBody>
                    {this.renderQuestionResults()}
                </ModalBody>
            </Modal>
        );
    }
}

SvoyaIgraGameResultAnsweredQuestionModal.propTypes = {
    gameType: PropTypes.string.isRequired,
    gameResult: PropTypes.object,
    editQuestionResultItem: PropTypes.object,
    hideEditQuestionResultItem: PropTypes.func.isRequired,
    deleteQuestionResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    gameResult: getItem(state),
    editQuestionResultItem: getEditQuestionResultItem(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    editQuestionResult: editQuestionResult,
    hideEditQuestionResultItem: hideEditQuestionResultItem,
    deleteQuestionResult: deleteQuestionResult,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultAnsweredQuestionModal);
