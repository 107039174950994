import {BACKEND_URL, getToken} from "./common";
import {TYPE_SI} from "../models/GameType";

async function index(gameId, gameType) {
    const url = `${BACKEND_URL}/v1/game-result/${gameType}/${gameId}`;

    let response = await fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function view(gameResultId, gameId, gameType) {
    let url = `${BACKEND_URL}/v1/game-result/${gameType}/${gameId}/${gameResultId}`;
    if (gameType === TYPE_SI) {
        url += '?expand=rounds,questionResults';
    }

    let response = await fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function add(gameResult, gameType) {
    console.log('add called with params ' + JSON.stringify(gameResult) + ' gameType = ' + gameType);
    const url = `${BACKEND_URL}/v1/game-result/${gameType}`;

    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(gameResult),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function edit(gameResult, gameType) {
    const url = `${BACKEND_URL}/v1/game-result/${gameType}/${gameResult._id}`;

    let response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(gameResult),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });

    response = response.json();
    return response;
}

async function del(gameResult, gameType) {
    const url = `${BACKEND_URL}/v1/game-result/${gameType}/${gameResult._id}`;

    return await fetch(url, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
}

export {index, view, add, edit, del};