import React from 'react';
import {Badge, Button, Card, CardBody, Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {getName} from '../models/GameType';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {deleteGame, getEditGameItem, showEditGameItem,} from '../ducks/tournamentView';
import GameEditItem from './GameEditItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import DeleteButton from "./DeleteButton";

class GameItem extends React.Component {

    onDelete = () => {
        this.props.deleteGame(this.props.item);
    };

    onEdit = () => {
        this.props.showEditGameItem(this.props.item);
    };

    render() {

        if (this.props.editGameItem !== null && this.props.editGameItem._id === this.props.item._id) {
            return <GameEditItem item={this.props.editItem}/>
        }

        const item = this.props.item;

        return (<>
            <Card className={'my-3'}>
                <CardBody>
                    <Row>
                        <Col>
                            <h4>
                                {item.name}
                                <Button color={'link'} onClick={() => this.onEdit()}>
                                    <FontAwesomeIcon icon={faPen}/>
                                </Button>
                                <Badge color={'info ml-2'}>{getName(item.game_type_id)}</Badge>
                            </h4>

                        </Col>
                        <Col>
                            <span className={'float-right'}>
                                    <Link className={'btn btn-info mr-2'} to={`/game-result/${item.game_type_id}/${item._id}`}>
                                        Прохождения
                                    </Link>
                                    <Link className={'btn btn-success'} to={`/game/${item.game_type_id}/${item._id}`}>
                                        Подробнее
                                    </Link>
                                    <DeleteButton onDelete={() => this.onDelete()}/>
                            </span>

                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>);
    }
};

const mapStateToProps = (state) => ({
    editGameItem: getEditGameItem(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showEditGameItem, deleteGame}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (GameItem);