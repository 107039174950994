import React, {Component} from 'react';

class BrainRingGameEditItem extends Component {
    
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

BrainRingGameEditItem.propTypes = {};

export default BrainRingGameEditItem;
