import * as ApiTournament from '../api/tournament';
import {call, put, takeLatest} from 'redux-saga/effects';

//actions

const INDEX = 'igames/tournamentIndex/INDEX';
const INDEX_SUCCEED = 'igames/tournamentIndex/INDEX_SUCCEED';
const ADD = "igames/tournamentIndex/ADD";
const EDIT = 'igames/tournamentIndex/EDIT';
const DELETE = 'igames/tournamentIndex/DELETE';
const ERROR = 'igames/tournamentIndex/ERROR';
const SHOW_ADD_ITEM = 'igames/tournamentIndex/SHOW_ADD_ITEM';
const HIDE_ADD_ITEM = 'igames/tournamentIndex/HIDE_ADD_ITEM';
const SHOW_EDIT_ITEM = 'igames/tournamentIndex/SHOW_EDIT_ITEM';
const HIDE_EDIT_ITEM = 'igames/tournamentIndex/HIDE_EDIT_ITEM';

const initialState = {
    items: [],
    errors: [],
    loading: false,
    addItem: null,
    editItem: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case INDEX:
            return {
                ...state,
                loading: true,
            };
        case INDEX_SUCCEED:
            return {
                ...state,
                items: action.payload,
                loading: false,
                errors: [],
            };
        case ERROR:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case SHOW_ADD_ITEM: 
            return {
                ...state,
                addItem: action.payload,
            };
        case HIDE_ADD_ITEM:
            return {
                ...state,
                addItem: null,
            };
        case SHOW_EDIT_ITEM:
            return {
                ...state,
                editItem: action.payload,
            };
        case HIDE_EDIT_ITEM:
            return {
                ...state,
                editItem: null,
            };
        default:
            return state;
    }
};

//selectors

export function getState(state) {
    return state.tournamentIndex;
}

export function getItems(state) {
    return getState(state).items;
}

export function getLoading(state) {
    return getState(state).loading;
}

export function getErrors(state) {
    return getState(state).errors;
}

export function getAddItem(state) {
    return getState(state).addItem;
}

export function getEditItem(state) {
    return getState(state).editItem;
}

//action creators
export function index() {
    return {type: INDEX}
}

export function indexSucceed(payload) {
    return {type: INDEX_SUCCEED, payload: payload}
}

export function add(tournament) {
    return {
        type: ADD,
        payload: tournament
    };
}

export function showAddItem(item) {
    if (item === undefined) {
        item = {
            name: ''
        }
    }
    return {
        type: SHOW_ADD_ITEM,
        payload: item,
    }
}

export function hideAddItem() {
    return {
        type: HIDE_ADD_ITEM,
    }
}

export function showEditItem(item) {
    return {
        type: SHOW_EDIT_ITEM,
        payload: item,
    }
}

export function hideEditItem() {
    return {
        type: HIDE_EDIT_ITEM,
    }
}

export function error(errors) {
    return {
        type: ERROR,
        payload: errors
    }
}

export function edit(item) {
    return {
        type: EDIT,
        payload: item,
    }
}

export function del(tournamentId) {
    return {
        type: DELETE,
        payload: tournamentId,
    }
}

//sagas

function* fetchIndex(action) {
    try {
        const response = yield call(ApiTournament.index);
        if (response.status === true) {
            yield put(indexSucceed(response.payload));
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchAdd(action) {
    try {
        const response = yield call(ApiTournament.add, action.payload);
        if (response.status === true) {
            yield put(index());
            yield put(hideAddItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchEdit(action) {
    try {
        const response = yield call(ApiTournament.edit, action.payload);
        if (response.status === true) {
            yield put(index());
            yield put(hideEditItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}


function* fetchDelete(action) {
    try {
        yield call(ApiTournament.del, action.payload);
        yield put(index());
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}


export function* tournamentIndexSaga() {
    yield takeLatest(INDEX, fetchIndex);
    yield takeLatest(ADD, fetchAdd);
    yield takeLatest(EDIT, fetchEdit);
    yield takeLatest(DELETE, fetchDelete);
}

