import React from 'react';
import {Col, Row,} from 'reactstrap';
import SvoyaIgraRoundItem from "./SvoyaIgraRoundItem";
import SvoyaIgraAddRound from "./SvoyaIgraAddRound";
import {getActiveRound} from "../../ducks/gameView";
import {connect} from 'react-redux';
import SvoyaIgraRoundView from "./SvoyaIgraRoundView";

class SvoyaIgraGameView extends React.Component {

    render() {
        const rounds = this.props.item.rounds;
        return (
            <Row>
                <Col className={'col-3'}>
                    <h5 className={'text-center'}>Список раундов</h5>
                    {rounds.map((item) => <SvoyaIgraRoundItem key={item._id} item={item}/>)}
                    <SvoyaIgraAddRound game={this.props.item}/>
                </Col>
                <Col>
                    <SvoyaIgraRoundView round={this.props.activeRound}/>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    activeRound: getActiveRound(state),
});

export default connect(mapStateToProps, null) (SvoyaIgraGameView);