import * as ApiGameResult from '../api/gameResult';
import {call, put, takeLatest} from 'redux-saga/effects';

//actions

const INDEX = 'igames/gameResultIndex/INDEX';
const INDEX_SUCCEED = 'igames/gameResultIndex/INDEX_SUCCEED';
const ADD = "igames/gameResultIndex/ADD";
const EDIT = 'igames/gameResultIndex/EDIT';
const DELETE = 'igames/gameResultIndex/DELETE';
const ERROR = 'igames/gameResultIndex/ERROR';
const SHOW_ADD_ITEM = 'igames/gameResultIndex/SHOW_ADD_ITEM';
const HIDE_ADD_ITEM = 'igames/gameResultIndex/HIDE_ADD_ITEM';
const SHOW_EDIT_ITEM = 'igames/gameResultIndex/SHOW_EDIT_ITEM';
const HIDE_EDIT_ITEM = 'igames/gameResultIndex/HIDE_EDIT_ITEM';

const initialState = {
    items: [],
    errors: [],
    loading: false,
    addItem: null,
    editItem: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case INDEX:
            return {
                ...state,
                loading: true,
            };
        case INDEX_SUCCEED:
            return {
                ...state,
                items: action.payload,
                loading: false,
                errors: [],
            };
        case ERROR:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case SHOW_ADD_ITEM:
            return {
                ...state,
                addItem: action.payload,
            };
        case HIDE_ADD_ITEM:
            return {
                ...state,
                addItem: null,
            };
        case SHOW_EDIT_ITEM:
            return {
                ...state,
                editItem: action.payload,
            };
        case HIDE_EDIT_ITEM:
            return {
                ...state,
                editItem: null,
            };
        default:
            return state;
    }
};

//selectors

export function getState(state) {
    return state.gameResultIndex;
}

export function getItems(state) {
    return getState(state).items;
}

export function getLoading(state) {
    return getState(state).loading;
}

export function getErrors(state) {
    return getState(state).errors;
}

export function getAddItem(state) {
    return getState(state).addItem;
}

export function getEditItem(state) {
    return getState(state).editItem;
}

//action creators
export function index(gameId, gameType) {
    return {type: INDEX, payload: {gameId, gameType}}
}

export function indexSucceed(payload) {
    return {type: INDEX_SUCCEED, payload: payload}
}

export function add(gameResult, gameType) {
    return {
        type: ADD,
        payload: {gameResult, gameType}
    };
}

export function showAddItem(item, gameType, gameId) {
    if (item === undefined) {
        item = {
            name: (new Date()).toLocaleString(),
            game_type_id: gameType,
            game_id: gameId,
        }
    }
    return {
        type: SHOW_ADD_ITEM,
        payload: item,
    }
}

export function hideAddItem() {
    return {
        type: HIDE_ADD_ITEM,
    }
}

export function showEditItem(item) {
    if (item === undefined) {
        item = {
            name: (new Date()).toLocaleString()
        }
    }
    return {
        type: SHOW_EDIT_ITEM,
        payload: item,
    }
}

export function hideEditItem() {
    return {
        type: HIDE_EDIT_ITEM,
    }
}

export function error(errors) {
    return {
        type: ERROR,
        payload: errors
    }
}

export function edit(item, gameType) {
    return {
        type: EDIT,
        payload: {gameResult: item, gameType},
    }
}

export function del(gameResult, gameType) {
    return {
        type: DELETE,
        payload: {gameResult, gameType},
    }
}

//sagas

function* fetchIndex(action) {
    try {
        const {gameId, gameType} = action.payload;
        const response = yield call(ApiGameResult.index, gameId, gameType);
        if (response.status === true) {
            yield put(indexSucceed(response.payload));
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchAdd(action) {
    try {
        console.log('in fetchAdd payload = ' + JSON.stringify(action.payload));
        const {gameResult, gameType} = action.payload;
        const response = yield call(ApiGameResult.add, gameResult, gameType);
        if (response.status === true) {
            yield put(index(gameResult.game_id, gameType));
            yield put(hideAddItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchEdit(action) {
    try {
        const {gameResult, gameType} = action.payload;
        const response = yield call(ApiGameResult.edit, gameResult, gameType);
        if (response.status === true) {
            yield put(index(gameResult.game._id, gameType));
            yield put(hideEditItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}


function* fetchDelete(action) {
    try {
        const {gameResult, gameType} = action.payload;
        yield call(ApiGameResult.del, gameResult, gameType);
        yield put(index(gameResult.game._id, gameType));
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}


export function* gameResultIndexSaga() {
    yield takeLatest(INDEX, fetchIndex);
    yield takeLatest(ADD, fetchAdd);
    yield takeLatest(EDIT, fetchEdit);
    yield takeLatest(DELETE, fetchDelete);
}

