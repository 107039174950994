import React from 'react';
import {Alert} from 'reactstrap';
import {connect} from 'react-redux';


class ErrorAlert extends React.Component {

    render() {
        return (
            <Alert color={'warning'}
                   isOpen={true}>
                {this.props.errors.map(error => error.message).join(',')}
            </Alert>
        )
    }
}

const ConnectedErrorAlert = connect(null, null)(ErrorAlert);

const renderErrors = (errors) => {
    if (errors && errors.length > 0) {
        return (
            <ConnectedErrorAlert errors={errors}/>
        );
    }
};

export {renderErrors};