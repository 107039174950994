import React from 'react';
import {Button, Card, CardHeader, Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {del, getEditItem, hideEditItem, showEditItem} from '../ducks/gameResultIndex';
import DeleteButton from "./DeleteButton";
import GameResultEditItem from "./GameResultEditItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";

class GameResultItem extends React.Component {

    onDelete = () => {
        this.props.del(this.props.item, this.props.gameType);
    };

    onEdit = () => {
        this.props.showEditItem(this.props.item);
    };

    render() {
        const {gameType} = this.props;
        const gameId = this.props.item.game._id;
        if (this.props.editItem !== null && this.props.editItem._id === this.props.item._id) {
            return <GameResultEditItem item={this.props.editItem} gameType={gameType} gameId={gameId}/>
        }

        return (<>
            <Card className={'my-3'}>
                <CardHeader>
                    <Row>
                        <Col>
                            <h4>
                                {this.props.item.name}
                                <Button color={'link'} onClick={() => this.onEdit()}>
                                    <FontAwesomeIcon icon={faPen}/>
                                </Button>
                            </h4>
                        </Col>
                        <Col>
                            <span className={'float-right'}>
                                <Link className={'btn btn-success mx-1'} to={`/game-result/${gameType}/${gameId}/${this.props.item._id}`}>
                                    Подробнее
                                </Link>
                                <DeleteButton onDelete={() => this.onDelete()}/>
                            </span>

                        </Col>
                    </Row>
                </CardHeader>
                {/*<CardBody>*/}
                {/*<Row>*/}
                {/*<Col>*/}
                {/**/}
                {/*</Col>*/}
                {/*</Row>*/}
                {/*</CardBody>*/}
            </Card>
        </>);
    }
}

GameResultItem.propTypes = {
    item: PropTypes.object,
    gameType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    editItem: getEditItem(state)
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({del, showEditItem, hideEditItem}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GameResultItem);