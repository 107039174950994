import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import {getActiveQuestion, getAddQuestionResultItem, getItem} from "../../../ducks/gameResultView";

class SvoyaIgraPresenterScreenQuestionModal extends Component {

    isModalDisplayed() {
        return this.props.question !== undefined && this.props.question !== null;
    }

    render() {
        const question = this.props.question;

        if (!this.isModalDisplayed()) {
            return null;
        }


        const player = this.props.addQuestionResultItem ?
            this.props.players.find((element) => element._id === this.props.addQuestionResultItem.player_id)
            : null;

        return (
            <Modal isOpen={true}>
                <ModalHeader>
                    {question.price}
                </ModalHeader>
                <ModalBody style={{fontSize: 20}}>
                    <h4>Вопрос</h4>
                    {question.question}
                    <hr/>
                    <h4>Ответ</h4>
                    {question.answer}
                </ModalBody>
                <ModalFooter style={{fontSize: 20}}>
                    {player
                    && <span className={'m-1'} color={'primary'}>
                        {player.name}<span className={'badge'} style={{
                        marginLeft: 5,
                        backgroundColor: player.color
                    }}>&nbsp;&nbsp;</span></span>
                    }
                </ModalFooter>
            </Modal>
        );
    }
}

SvoyaIgraPresenterScreenQuestionModal.propTypes = {
    gameType: PropTypes.string.isRequired,
    question: PropTypes.object,
    players: PropTypes.array.isRequired,
    addQuestionResultItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
    question: getActiveQuestion(state),
    players: getItem(state).players,
    addQuestionResultItem: getAddQuestionResultItem(state),
});

export default connect(mapStateToProps)(SvoyaIgraPresenterScreenQuestionModal);
