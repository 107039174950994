import React, {Component} from 'react';
import PropTypes from "prop-types";

class SvolyaIgraUserScreenActivePlayerView extends Component {
    render() {
        const styles = {
            width: this.props.width,
            height: this.props.height,
            overflow: 'hidden',
            backgroundColor: 'mediumslateblue',
        };
        if (this.props.activePlayer) {
            styles.backgroundColor = this.props.activePlayer.color;
        }
        return (
            <div style={styles} className={'justify-content-center SvoyaIgra-background'}>
                <div style={{padding: '50px', width: this.props.width, height: this.props.height}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

SvolyaIgraUserScreenActivePlayerView.propTypes = {
    activePlayer: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default SvolyaIgraUserScreenActivePlayerView;
