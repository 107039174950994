import React from 'react';
import SvoyaIgraQuestionCategoryEditItem from "./SvoyaIgraQuestionCategoryEditItem";
import {deleteQuestionCategory, getEditQuestionCategoryItem, showEditQuestionCategoryItem} from "../../ducks/gameView";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Card, CardBody, CardHeader, Col, Row,} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {TYPE_SI} from "../../models/GameType";
import SvoyaIgraAddQuestion from './SvoyaIgraAddQuestion';
import SvoyaIgraQuestionItem from './SvoyaIgraQuestionItem'
import DeleteButton from "../DeleteButton";

const SvoyaIgraQuestionCategoryItem = (props) => {
    let header = null;
    if (props.editItem !== null && props.item._id === props.editItem._id) {
        header = <SvoyaIgraQuestionCategoryEditItem item={props.editItem}/>
    } else {
        header = (<Row noGutters>
                    <Col>
                        <h4>{props.item.name}</h4>
                    </Col>
                    <Col className={'col-sm-auto'}>
                        <Button color={'link'}
                                onClick={() => props.showEditQuestionCategoryItem(props.item)}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </Button>
                        <DeleteButton onDelete={() => props.deleteQuestionCategory(props.item, TYPE_SI)}/>
                    </Col>
                </Row>);
    }

    return (
        <Card className={'my-2'}>
            <CardHeader className={'bg-warning'}>
                {header}
            </CardHeader>
            <CardBody>
                {props.item.questions.map((item) => <SvoyaIgraQuestionItem key={item._id} item={item}/>)}
                <SvoyaIgraAddQuestion category={props.item}/>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    editItem: getEditQuestionCategoryItem(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showEditQuestionCategoryItem, deleteQuestionCategory}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (SvoyaIgraQuestionCategoryItem);