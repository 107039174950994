import React from 'react';
import PropTypes from 'prop-types';
import {getAddQuestionResultItem, getItem} from "../../../ducks/gameResultView";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setFullscreen, unsetFullscreen} from "../../../ducks/common";
import {getUser} from "../../../ducks/user";
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import PlayerScreenActivePlayerView from "./PlayerScreenActivePlayerView";

class PlayerScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: 0,
            windowHeight: 0,
            animationBackgroundColor: '#000',
        }
    }

    updateDimensions() {
        this.setState((prevState, props) => ({
            ...prevState,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        }))
    }

    componentDidMount() {
        this.updateDimensions();
        this.props.setFullscreen();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        this.props.unsetFullscreen();
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    activePlayer(props) {
        if (!props.item) {
            return null;
        }

        const accessToken = props.user.access_token;
        const decoded = jwt.decode(accessToken);
        const username = decoded.username;

        console.log("current username ", username);
        console.log("result players", JSON.stringify(props.item.players, null, 2));

        return _.find(props.item.players, player => player.username === username);
    }

    render() {
        const boundaryWidth = 50;
        const playerPanelHeight = 130;
        const activePlayer = this.activePlayer(this.props);
        const backgroundColor = activePlayer ? activePlayer.color : 'mediumslateblue';
        console.log('active player = ', JSON.stringify(activePlayer));
        const contentWidth = this.state.windowWidth - boundaryWidth * 2;
        const contentHeight = this.state.windowHeight - boundaryWidth * 2 - playerPanelHeight;
        const scoreFontSize = contentHeight - 50;
        const playerNameFontSize = playerPanelHeight - 20;
        const fontColor = 'white';
        const content = activePlayer
            ? <div>
                <div style={{
                    color: fontColor,
                    width: contentWidth, fontSize: playerNameFontSize, lineHeight: playerNameFontSize + 'px', height: playerPanelHeight,
                    textAlign: 'center', verticalAlign: 'middle'
                }}>{activePlayer.name}</div>
                <div style={{
                    color: fontColor,
                    fontSize: scoreFontSize, lineHeight: scoreFontSize + 'px', height: contentHeight, width: contentWidth,
                    textAlign: 'center', verticalAlign: 'middle',
                }}>{activePlayer.score}</div>
            </div>
            : <></>;

        return (
            <PlayerScreenActivePlayerView player={activePlayer}
                                          boundaryWidth={50}
                                          userAnswered={this.state.userAnswered}
                                          addQuestionResultItem={this.props.addQuestionResultItem}
                                          width={this.state.windowWidth}
                                          height={this.state.windowHeight}
                                          color={'white'}
            >
                <div style={{
                    color: '#000',
                    width: contentWidth, height: contentHeight + playerPanelHeight,
                    overflow: 'hidden', backgroundColor: backgroundColor
                }} className={'SvoyaIgra-background'}>
                    {content}
                </div>
            </PlayerScreenActivePlayerView>
        )
    }
}

PlayerScreen.propTypes = {
    item: PropTypes.object,
    addQuestionResultItem: PropTypes.object,
};

const mapStateToProps = state => ({
    item: getItem(state),
    user: getUser(state),
    addQuestionResultItem: getAddQuestionResultItem(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setFullscreen,
    unsetFullscreen,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlayerScreen);
