import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Card, CardBody, CardFooter, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {TYPE_SI} from "../../../models/GameType";
import DeleteButton from "../../DeleteButton";
import {deletePlayer, getEditPlayerItem, showEditPlayerItem} from "../../../ducks/gameResultView";
import SvoyaIgraGameResultPlayerEditItem from "./SvoyaIgraGameResultPlayerEditItem";

const SvoyaIgraGameResultPlayerItem = (props) => {
    if (props.editItem !== null && props.item._id === props.editItem._id) {
        return (
            <div className="my-1">
                <SvoyaIgraGameResultPlayerEditItem item={props.editItem}/>
            </div>
        );
    }

    return (
        <Card className={'my-2'}>
            <CardBody>
                <Row noGutters className={'h-100'}>
                    <Col sm={1} style={{
                        backgroundColor: props.item.color,
                    }}>
                    </Col>
                    <Col className={'d-flex align-items-center justify-content-center'}>
                        <span className={'align-middle'}>{props.item.name}</span>
                    </Col>
                    <Col className={'col-sm-auto'}>
                        <Button color={'link'}
                                onClick={() => props.showEditPlayerItem(props.item)}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </Button>
                        <DeleteButton onDelete={() => props.deletePlayer(props.item, TYPE_SI)}/>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <span className={'float-left'}>{props.item.username}</span>
                <span className={'float-right'}>Очков: {props.item.score}</span>
            </CardFooter>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    editItem: getEditPlayerItem(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showEditPlayerItem, deletePlayer}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultPlayerItem);
