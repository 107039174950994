import {BACKEND_URL, getToken} from "./common";

async function add(round, gameType) {
    const url = `${BACKEND_URL}/v1/round/${gameType}`;

    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(round),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function edit(round, gameType) {
    const url = `${BACKEND_URL}/v1/round/${gameType}/${round._id}`;

    let response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(round),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });

    response = response.json();
    return response;
}

async function del(round, gameType) {
    const url = `${BACKEND_URL}/v1/round/${gameType}/${round._id}`;

    return await fetch(url, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
}

export {add, edit, del};