import React from 'react';
import {getActiveRoundId, setActiveRound,} from "../../../ducks/gameResultView";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {NavItem, NavLink} from 'reactstrap';
import PropTypes from 'prop-types';

const SvoyaIgraGameResultRoundItem = (props) => {

    let className = 'my-2';
    if (props.item._id === props.activeRoundId) {
        className += ' Active-item';
    }

    return (
        <NavItem style={{cursor: 'pointer'}}>
            <NavLink active={props.item._id === props.activeRoundId} onClick={() => props.setActiveRound(props.item._id)}>
                <h6>{props.item.name}</h6>
            </NavLink>
        </NavItem>
    );
};

SvoyaIgraGameResultRoundItem.propTypes = {
    item: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    activeRoundId: getActiveRoundId(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({setActiveRound}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultRoundItem);