import {BACKEND_URL, getToken} from "./common";

async function add(questionResult, gameType) {
    const url = `${BACKEND_URL}/v1/question-result/${gameType}`;

    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(questionResult),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
    response = response.json();
    return response;
}

async function del(questionResult, gameType) {
    console.log(questionResult);
    const url = `${BACKEND_URL}/v1/question-result/${gameType}/${questionResult._id}`;

    return await fetch(url, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${getToken()}`
        }
    });
}

export {add, del};
