import React from 'react';
import PropTypes from 'prop-types';
import SvoyaIgraPresenterScreenQuestionCategory from "./SvoyaIgraPresenterScreenQuestionCategory";

class SvoyaIgraPresenterScreenQuestionTable extends React.Component {
    render() {
        if (this.props.round == null) {
            return null;
        }

        const rowHeight = this.props.height / this.props.round.categories.length;
        return this.props.round.categories.map((item) =>
            <SvoyaIgraPresenterScreenQuestionCategory key={item._id} category={item} height={rowHeight}/>);
    }
}

SvoyaIgraPresenterScreenQuestionTable.propTypes = {
    round: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default SvoyaIgraPresenterScreenQuestionTable;
