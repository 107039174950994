import React from 'react';
import {del, getAddItem, getErrors, getItems, index, showAddItem,} from "../ducks/gameResultIndex";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {renderErrors} from "./common";
import AddItemList from "./AddItemList";
import GameResultItem from './GameResultItem';
import GameResultEditItem from "./GameResultEditItem";


class GameResultIndex extends React.Component {

    componentDidMount() {
        const {gameId, gameType} = this.props.match.params;
        this.props.index(gameId, gameType);
    }

    isAddItemDisplayed = () => this.props.addItem !== null;

    render() {
        const {gameId, gameType} = this.props.match.params;
        return (
            <>

                {renderErrors(this.props.errors)}

                <AddItemList
                    header={'Прохождения игры'}
                    onAddButtonClick={() => this.props.showAddItem(undefined, gameType, gameId)}
                    addButtonText={'Добавить'}
                    renderAddItem={() =>
                        <GameResultEditItem item={this.props.addItem} gameId={gameId} gameType={gameType}/>}
                    renderItem={(item) => <GameResultItem key={item._id} item={item} gameType={gameType}/>}
                    displayAddButton={!this.isAddItemDisplayed()}
                    displayAddItem={this.isAddItemDisplayed()}
                    data={this.props.items}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: getItems(state),
        errors: getErrors(state),
        addItem: getAddItem(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({index, showAddItem, del}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(GameResultIndex);