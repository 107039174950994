import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, FormGroup, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addPlayer, editPlayer, hideAddPlayerItem, hideEditPlayerItem} from '../../../ducks/gameResultView';
import {TYPE_SI} from "../../../models/GameType";
import {CompactPicker as ColorPicker} from 'react-color';
import {getList as getUserList} from "../../../ducks/user";

class SvoyaIgraGameResultPlayerEditItem extends Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({}, this.props.item);
    }

    setName(name) {
        this.setState((prevState) => ({
            ...prevState,
            name: name,
        }));
    }

    setColor(color) {
        this.setState((prevState) => ({
            ...prevState,
            color: color.hex,
        }))
    }

    setUsername(username) {
        this.setState((prevState) => ({
            ...prevState,
            username,
        }))
    }

    onSubmit(event) {
        if (this.state._id !== undefined) {
            this.props.editPlayer(this.state, TYPE_SI);
        } else {
            this.props.addPlayer(this.state, TYPE_SI);
        }
        event.preventDefault();
    }

    onCancel() {
        if (this.state._id !== undefined) {
            this.props.hideEditPlayerItem()
        } else {
            this.props.hideAddPlayerItem()
        }
    }

    render() {
        return (
            <Form onSubmit={(event) => this.onSubmit(event)}>
                <hr/>
                <FormGroup>
                    <InputGroup>
                        <Input
                            type={'text'}
                            placeholder={'Имя игрока'}
                            value={this.state.name}
                            onChange={(event) => this.setName(event.target.value)}
                        />
                        <InputGroupAddon addonType={'append'}>
                            <Button onClick={(event) => this.onSubmit(event)} color={'success'}>
                                <FontAwesomeIcon icon={faCheck}/>
                            </Button>
                            <Button onClick={() => this.onCancel()}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>

                <FormGroup>
                    <InputGroup>
                        <Input type={'select'} value={this.state.username !== undefined && this.state.username !== null ? this.state.username : ""}
                               onChange={(event) => this.setUsername(event.target.value)}>
                            <option value={""}>WS клиент</option>
                            {this.props.users.map((item) =>
                                <option key={item.id} value={item.username}>{item.username}</option>)}
                        </Input>
                    </InputGroup>
                </FormGroup>

                <FormGroup>
                    <ColorPicker color={this.state.color} onChange={(color) => this.setColor(color)}/>
                </FormGroup>
                <hr/>
            </Form>
        );
    }
}

SvoyaIgraGameResultPlayerEditItem.propTypes = {
    item: PropTypes.object.isRequired,
    users: PropTypes.array,
};

const mapStateToProps = state => ({
    users: getUserList(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({hideAddPlayerItem, hideEditPlayerItem, addPlayer, editPlayer}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultPlayerEditItem);
