import React from 'react';
import Login from "./Login";
import NavBar from './NavBar';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {isAuthenticated} from "../ducks/user";
import {connect} from 'react-redux';
import TournamentIndex from "./TornamentIndex";
import {Container} from 'reactstrap'
import TournamentView from "./TournamentView";
import GameView from './GameView';
import GameResultIndex from './GameResultIndex';
import GameResultView from './GameResultView';
import GameUserScreen from "./GameUserScreen";
import {isFullscreen} from '../ducks/common';
import GamePresenterScreen from "./GamePresenterScreen";
import {connectWebsocket} from "../ducks/ws";
import {bindActionCreators} from "redux";
import PlayerScreen from "./svoyaigra/playerScreen/PlayerScreen";

class RouterComponent extends React.Component {
    componentDidMount() {
        this.props.connectWebsocket();
    }

    render() {
        const isAuthenticated = this.props.isAuthenticated;

        if (!isAuthenticated) {
            return (
                <Router>
                    <div>
                        <Route path="/login" component={Login}/>
                        <Redirect to={'/login'}/>
                    </div>
                </Router>
            )
        }

        console.log('is fullscreen inside route' + this.props.isFullscreen);

        if (this.props.isFullscreen) {
            return (
                <Router>
                    {renderSwitch()}
                </Router>
            );
        }

        return (
            <Router>
                <div>
                    <NavBar/>
                    <hr/>
                    <Container>
                        {renderSwitch()}
                    </Container>
                </div>
            </Router>
        );
    }
}

const renderSwitch = () => (
    <Switch>
        <Route path={`/game/:gameType/:gameId`} component={GameView}/>
        <Route path={`/tournament/:tournamentId`} component={TournamentView}/>
        <Route path={`/game-result/:gameType/:gameId/:gameResultId`} component={GameResultView}/>
        <Route path={`/user-screen`} component={GameUserScreen}/>
        <Route path={`/presenter-screen/:gameType`} component={GamePresenterScreen}/>
        <Route path={`/player-screen`} component={PlayerScreen}/>
        <Route path={`/game-result/:gameType/:gameId`} component={GameResultIndex}/>
        <Route path="/login" component={Login}/>
        <Route path="/" component={TournamentIndex}/>
    </Switch>
);

const mapStateToProps = (state) => ({
    isAuthenticated: isAuthenticated(state),
    isFullscreen: isFullscreen(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    connectWebsocket,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);

