import * as ApiGameResult from '../api/gameResult';
import * as ApiPlayer from '../api/player'
import * as ApiQuestionResult from '../api/questionResult'

import {call, put, select, takeLatest} from 'redux-saga/effects';
import _ from 'lodash';
import Player from '../models/Player';
import QuestionResult from "../models/QuestionResult";

//actions

const VIEW = "igames/gameResultView/VIEW";
export const VIEW_SUCCEED = "igames/gameResultView/VIEW_SUCCEED";

const ADD_PLAYER = 'igames/gameResultView/ADD_PLAYER';
const EDIT_PLAYER = 'igames/gameResultView/EDIT_PLAYER';
const DELETE_PLAYER = 'igames/gameResultView/DELETE_PLAYER';

export const ADD_QUESTION_RESULT = 'igames/gameResultView/ADD_QUESTION_RESULT';
export const ADD_WRONG_QUESTION_RESULT = 'igames/gameResultView/ADD_WRONG_QUESTION_RESULT';
const EDIT_QUESTION_RESULT = 'igames/gameResultView/EDIT_QUESTION_RESULT';
const DELETE_QUESTION_RESULT = 'igames/gameResultView/DELETE_QUESTION_RESULT';

const ERROR = 'igames/gameView/ERROR';

const SHOW_ADD_PLAYER = 'igames/gameResultView/SHOW_ADD_PLAYER';
const HIDE_ADD_PLAYER = 'igames/gameResultView/HIDE_ADD_PLAYER';
const SHOW_EDIT_PLAYER = 'igames/gameResultView/SHOW_EDIT_PLAYER';
const HIDE_EDIT_PLAYER = 'igames/gameResultView/HIDE_EDIT_PLAYER';

export const SHOW_ADD_QUESTION_RESULT = 'igames/gameResultView/SHOW_ADD_QUESTION_RESULT';
export const HIDE_ADD_QUESTION_RESULT = 'igames/gameResultView/HIDE_ADD_QUESTION_RESULT';
const SHOW_EDIT_QUESTION_RESULT = 'igames/gameResultView/SHOW_EDIT_QUESTION_RESULT';
const HIDE_EDIT_QUESTION_RESULT = 'igames/gameResultView/HIDE_EDIT_QUESTION_RESULT';

export const SET_ACTIVE_ROUND = 'igames/gameResultView/SET_ACTIVE_ROUND';
export const UNSET_ACTIVE_ROUND = 'igames/gameResultView/UNSET_ACTIVE_ROUND';

export const SET_ACTIVE_QUESTION = 'igames/gameResultView/SET_ACTIVE_QUESTION';
export const UNSET_ACTIVE_QUESTION = 'igames/gameResultView/UNSET_ACTIVE_QUESTION';
export const BLOCK_BUTTONS = 'igames/gameResultView/BLOCK_BUTTONS';

const initialState = {
    item: null,
    errors: [],
    loading: false,

    addPlayerItem: null,
    editPlayerItem: null,

    addQuestionResultItem: null,
    editQuestionResultItem: null,

    activeRoundId: null,
    activeQuestion: null,

    answeredQuestionIds: new Set(),
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ERROR:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case SHOW_ADD_QUESTION_RESULT:
            const {gameType, playerIndex} = action.payload;
            const activeQuestion = state.activeQuestion;
            const activeGameResult = state.item;
            if (activeQuestion && activeGameResult) {
                const questionResult = QuestionResult.create(state.item,
                    state.activeQuestion,
                    state.item.players[playerIndex],
                    gameType);
                return {
                    ...state,
                    addQuestionResultItem: questionResult,
                };
            } else {
                return state;
            }

        case HIDE_ADD_QUESTION_RESULT:
            return {
                ...state,
                addQuestionResultItem: null,
            };
        case SHOW_EDIT_QUESTION_RESULT:
            console.log(action.payload);
            return {
                ...state,
                editQuestionResultItem: action.payload,
            };
        case HIDE_EDIT_QUESTION_RESULT:
            return {
                ...state,
                editQuestionResultItem: null,
            };
        case SHOW_ADD_PLAYER:
            return {
                ...state,
                addPlayerItem: action.payload,
            };
        case HIDE_ADD_PLAYER:
            return {
                ...state,
                addPlayerItem: null,
            };
        case SHOW_EDIT_PLAYER:
            return {
                ...state,
                editPlayerItem: action.payload,
            };
        case HIDE_EDIT_PLAYER:
            return {
                ...state,
                editPlayerItem: null,
            };
        case VIEW:
            return {
                ...state,
                loading: true,
            };
        case VIEW_SUCCEED:
            const answeredQuestionIdsSet = new Set();
            action.payload.questionResults.forEach((questionResult) => answeredQuestionIdsSet.add(questionResult.question_id));
            return {
                ...state,
                item: action.payload,
                answeredQuestionIds: answeredQuestionIdsSet,
                loading: false,
                errors: [],
            };
        case SET_ACTIVE_ROUND:
            return {
                ...state,
                activeRoundId: action.payload,
            };
        case UNSET_ACTIVE_ROUND:
            return {
                ...state,
                activeRoundId: null,
            };
        case SET_ACTIVE_QUESTION:
            return {
                ...state,
                activeQuestion: action.payload,
            };
        case UNSET_ACTIVE_QUESTION:
            return {
                ...state,
                activeQuestion: null,
            };
        default:
            return state;
    }
};

//selectors

export function getState(state) {
    return state.gameResultView;
}

export function getItem(state) {
    return getState(state).item;
}

export function getLoading(state) {
    return getState(state).loading;
}

export function getErrors(state) {
    return getState(state).errors;
}

export function getAddPlayerItem(state) {
    return getState(state).addPlayerItem;
}

export function getEditPlayerItem(state) {
    return getState(state).editPlayerItem;
}

export function getAddQuestionResultItem(state) {
    return getState(state).addQuestionResultItem;
}

export function getEditQuestionResultItem(state) {
    return getState(state).editQuestionResultItem;
}

export function getActiveQuestion(state) {
    return getState(state).activeQuestion;
}

export function getAnsweredQuestionIds(state) {
    let savedIds = getState(state).answeredQuestionIds;
    if (savedIds === undefined) {
        savedIds = new Set();
    }
    return savedIds;
}

export function getActiveRoundId(state) {
    let activeRoundId = getState(state).activeRoundId;
    if (activeRoundId !== null) {
        return activeRoundId;
    }

    const item = getItem(state);
    if (item !== null && item.rounds && item.rounds.length > 0) {
        return item.rounds[0]._id;
    }
}

export function getActiveRound(state) {
    const activeId = getActiveRoundId(state);
    console.warn('active round id = ', activeId);
    return _.find(getItem(state).rounds, (item) => item._id === activeId);
}

//action creators

export function viewSucceed(gameResult) {
    return {
        type: VIEW_SUCCEED,
        payload: gameResult
    }
}

export function view(gameResultId, gameId, gameType) {
    return {
        type: VIEW,
        payload: {gameResultId, gameId, gameType},
    }
}

export function addPlayer(player, gameType) {
    return {
        type: ADD_PLAYER,
        payload: {player, gameType}
    };
}

export function editPlayer(player, gameType) {
    return {
        type: EDIT_PLAYER,
        payload: {player, gameType},
    }
}

export function deletePlayer(player, gameType) {
    return {
        type: DELETE_PLAYER,
        payload: {player, gameType},
    }
}


export function error(errors) {
    return {
        type: ERROR,
        payload: errors
    }
}

export function showAddPlayerItem(gameResult, gameType) {
    const player = Player.create(gameResult, gameType);
    return {
        type: SHOW_ADD_PLAYER,
        payload: player,
    }
}

export function hideAddPlayerItem() {
    return {
        type: HIDE_ADD_PLAYER
    }
}

export function showEditPlayerItem(player) {
    return {
        type: SHOW_EDIT_PLAYER,
        payload: player,
    }
}

export function hideEditPlayerItem() {
    return {type: HIDE_EDIT_PLAYER};
}

export function setActiveRound(id) {
    return {type: SET_ACTIVE_ROUND, payload: id};
}

export function unsetActiveRound() {
    return {type: UNSET_ACTIVE_ROUND}
}

export function setActiveQuestion(question) {
    return {type: SET_ACTIVE_QUESTION, payload: question};
}

export function unsetActiveQuestion() {
    return {type: UNSET_ACTIVE_QUESTION}
}

// question category action creators

export function addQuestionResult(questionResult, gameType) {
    return {
        type: ADD_QUESTION_RESULT,
        payload: {questionResult, gameType}
    };
}


export function addWrongQuestionResult(questionResult, gameType) {
    return {
        type: ADD_WRONG_QUESTION_RESULT,
        payload: {questionResult, gameType}
    };
}

export function editQuestionResult(questionResult, gameType) {
    console.log('edit question result', questionResult, gameType);
    return {
        type: EDIT_QUESTION_RESULT,
        payload: {questionResult, gameType},
    }
}

export function deleteQuestionResult(questionResult, gameType) {
    return {
        type: DELETE_QUESTION_RESULT,
        payload: {questionResult, gameType},
    }
}

export function showAddQuestionResultItem(playerIndex, gameType) {

    return {
        type: SHOW_ADD_QUESTION_RESULT,
        payload: {playerIndex, gameType},
    }
}

export function hideAddQuestionResultItem() {
    return {
        type: HIDE_ADD_QUESTION_RESULT,
    }
}

export function showEditQuestionResultItem(questionResult) {
    return {
        type: SHOW_EDIT_QUESTION_RESULT,
        payload: questionResult,
    }
}

export function hideEditQuestionResultItem() {
    return {type: HIDE_EDIT_QUESTION_RESULT};
}

export function blockButtons() {
    return {type: BLOCK_BUTTONS};
}

//sagas

function* fetchView(action) {
    try {
        const response = yield call(ApiGameResult.view,
            action.payload.gameResultId,
            action.payload.gameId,
            action.payload.gameType,
        );
        if (response.status === true) {
            const gameResult = response.payload;
            yield put(viewSucceed(gameResult));
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchAddPlayer(action) {
    try {
        const response = yield call(ApiPlayer.add,
            action.payload.player,
            action.payload.gameType,
        );
        if (response.status === true) {
            const gameResult = yield select(getItem);
            yield put(view(gameResult._id, gameResult.game.id, action.payload.gameType));
            yield put(hideAddPlayerItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchEditPlayer(action) {
    const {player, gameType} = action.payload;
    console.log(player);
    try {
        const response = yield call(ApiPlayer.edit, player, gameType);
        if (response.status === true) {
            const gameResult = yield select(getItem);
            yield put(view(gameResult._id, gameResult.game._id, gameType));
            yield put(hideEditPlayerItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchDeletePlayer(action) {
    const {player, gameType} = action.payload;
    try {
        yield call(ApiPlayer.del, player, gameType);
        const gameResult = yield select(getItem);
        yield put(view(gameResult._id, gameResult.game._id, gameType));
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}


function* fetchAddQuestionResult(action) {
    const {questionResult, gameType} = action.payload;

    // Не делаем ничего если пришло событие с других клиентов
    if (action.remote) {
        console.log("prevent remote message handling");
        return;
    }

    try {
        const response = yield call(ApiQuestionResult.add, questionResult, gameType);
        if (response.status === true) {
            const gameResult = yield select(getItem);
            yield put(view(gameResult._id, gameResult.game._id, action.payload.gameType));
            yield put(hideAddQuestionResultItem());
            yield put(unsetActiveQuestion());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchDeleteQuestionResult(action) {
    const {questionResult, gameType} = action.payload;

    try {
        const response = yield call(ApiQuestionResult.del, questionResult, gameType);
        const gameResult = yield select(getItem);
        yield put(view(gameResult._id, gameResult.game._id, gameType));
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchAddWrongQuestionResult(action) {
    const {questionResult, gameType} = action.payload;

    // Не делаем ничего если пришло событие с других клиентов
    if (action.remote) {
        console.log("prevent remote message handling");
        return;
    }

    try {
        const response = yield call(ApiQuestionResult.add, questionResult, gameType);
        if (response.status === true) {
            const gameResult = yield select(getItem);
            yield put(view(gameResult._id, gameResult.game._id, action.payload.gameType));
            yield put(hideAddQuestionResultItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

export function* gameResultViewSaga() {
    yield takeLatest(VIEW, fetchView);

    yield takeLatest(ADD_PLAYER, fetchAddPlayer);
    yield takeLatest(EDIT_PLAYER, fetchEditPlayer);
    yield takeLatest(DELETE_PLAYER, fetchDeletePlayer);
    //
    yield takeLatest(ADD_QUESTION_RESULT, fetchAddQuestionResult);
    yield takeLatest(DELETE_QUESTION_RESULT, fetchDeleteQuestionResult);
    yield takeLatest(ADD_WRONG_QUESTION_RESULT, fetchAddWrongQuestionResult);
    // yield takeLatest(EDIT_QUESTION_CATEGORY, fetchEditQuestionCategory);
    // yield takeLatest(DELETE_QUESTION_CATEGORY, fetchDeleteQuestionCategory);
    //
    // yield takeLatest(ADD_QUESTION, fetchAddQuestion);
    // yield takeLatest(EDIT_QUESTION, fetchEditQuestion);
    // yield takeLatest(DELETE_QUESTION, fetchDeleteQuestion);
}

