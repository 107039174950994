import React, {Component} from 'react';
import {
    addGame,
    getAddGameItem,
    getErrors,
    getItem,
    hideAddGameItem,
    showAddGameItem,
    view,
} from '../ducks/tournamentView';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {renderErrors} from "./common";
import AddItemList from "./AddItemList";
import GameItem from "./GameItem";
import GameAddItem from './GameAddItem';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Link} from 'react-router-dom';

class TournamentView extends Component {

    componentDidMount() {
        const {tournamentId} = this.props.match.params;
        this.props.view(tournamentId);
    }

    isAddGameDisplayed = () => this.props.addGameItem !== null;

    toggleAddGameDisplayed = () => {
        if (this.isAddGameDisplayed()) {
            this.props.hideAddGameItem();
        } else {
            this.props.showAddGameItem(this.props.item._id);
        }
    };

    render() {

        if (this.props.item === null) {
            return <h2>Загрузка данных</h2>
        }

        return (
            <>
                <Breadcrumb>
                    <BreadcrumbItem><Link to={'/'}>Список турниров</Link></BreadcrumbItem>
                    <BreadcrumbItem active>
                        <Link to={`/tournament/${this.props.item._id}`}>{this.props.item.name}</Link>
                    </BreadcrumbItem>
                </Breadcrumb>
                {renderErrors(this.props.errors)}

                <AddItemList
                    header={'Список игр турнира'}
                    onAddButtonClick={() => this.toggleAddGameDisplayed()}
                    displayAddButton={true}
                    addButtonText={this.isAddGameDisplayed() ? 'Отменить' : 'Добавить игру'}
                    renderAddItem={() => <GameAddItem tournamentId={this.props.item._id}/>}
                    renderItem={(item) => <GameItem key={item._id} item={item}/>}
                    displayAddItem={this.isAddGameDisplayed()}
                    data={this.props.item.games}
                />
            </>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        item: getItem(state),
        addGameItem: getAddGameItem(state),
        errors: getErrors(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showAddGameItem, hideAddGameItem, view, addGame}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (TournamentView);
