import * as ApiGame from '../api/game';
import * as ApiTournament from '../api/tournament';
import {call, put, takeLatest} from 'redux-saga/effects';

//actions

const VIEW = "igames/tournamentView/VIEW";
const VIEW_SUCCEED = "igames/tournamentView/VIEW_SUCCEED";
const ADD_GAME = 'igames/tournamentView/ADD_GAME';
const EDIT_GAME = 'igames/tournamentView/EDIT_GAME';
const DELETE_GAME = 'igames/tournamentView/DELETE_GAME';
const ERROR = 'igames/tournamentView/ERROR';
const SHOW_ADD_GAME = 'igames/tournamentView/SHOW_ADD_GAME';
const HIDE_ADD_GAME = 'igames/tournamentView/HIDE_ADD_GAME';
const SHOW_EDIT_GAME = 'igames/tournamentView/SHOW_EDIT_GAME';
const HIDE_EDIT_GAME = 'igames/tournamentView/HIDE_EDIT_GAME';

const initialState = {
    item: null,
    errors: [],
    loading: false,
    addGameItem: null,
    editGameItem: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ERROR:
            return {
                ...state,
                errors: action.payload,
                loading: false,
            };
        case SHOW_ADD_GAME:
            return {
                ...state,
                addGameItem: action.payload,
            };
        case HIDE_ADD_GAME:
            return {
                ...state,
                addGameItem: null,
            };
        case SHOW_EDIT_GAME:
            return {
                ...state,
                editGameItem: action.payload,
            };
        case HIDE_EDIT_GAME:
            return {
                ...state,
                editGameItem: null,
            };
        case VIEW: 
            return {
                ...state,
                loading: true,
            };
        case VIEW_SUCCEED:
            return {
                ...state,
                item: action.payload,
                loading: false,
                errors: [],
            };
        default:
            return state;
    }
};

//selectors

export function getState(state) {
    return state.tournamentView;
}

export function getItem(state) {
    return getState(state).item;
}

export function getLoading(state) {
    return getState(state).loading;
}

export function getErrors(state) {
    return getState(state).errors;
}

export function getAddGameItem(state) {
    return getState(state).addGameItem;
}

export function getEditGameItem(state) {
    return getState(state).editGameItem;
}

//action creators

export function viewSucceed(tournament) {
    return {
        type: VIEW_SUCCEED,
        payload: tournament
    }
}

export function view(id) {
    return {
        type: VIEW,
        payload: id,
    }
}

export function addGame(gameType, game) {
    return {
        type: ADD_GAME,
        payload: {gameType, game}
    };
}

export function editGame(game) {
    return {
        type: EDIT_GAME,
        payload: game,
    }
}

export function error(errors) {
    return {
        type: ERROR,
        payload: errors
    }
}

export function deleteGame(game) {
    return {
        type: DELETE_GAME,
        payload: game,
    }
}

export function showAddGameItem(tournamentId) {
    const game = {
        name: '',
        tournament_id: tournamentId,
    };

    return {
        type: SHOW_ADD_GAME,
        payload: game,
    }
}

export function hideAddGameItem() {
    return {
        type: HIDE_ADD_GAME
    }
}

export function showEditGameItem(game) {
    return {
        type: SHOW_EDIT_GAME,
        payload: game,
    }
}

export function hideEditGameItem() {
    return {type: HIDE_EDIT_GAME};
}

//sagas

function* fetchView(action) {
    try {
        const response = yield call(ApiTournament.view, action.payload);
        if (response.status === true) {
            yield put(viewSucceed(response.payload));
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchAddGame(action) {
    try {
        const response = yield call(ApiGame.add,
            action.payload.gameType,
            action.payload.game
        );
        if (response.status === true) {
            yield put(view(action.payload.game.tournament_id));
            yield put(hideAddGameItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchEditGame(action) {
    try {
        const response = yield call(ApiGame.edit, action.payload);
        if (response.status === true) {
            yield put(view(action.payload.tournament_id));
            yield put(hideEditGameItem());
        } else {
            yield put(error(response.errors));
        }
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

function* fetchDeleteGame(action) {
    try {
        yield call(ApiGame.del, action.payload);
        yield put(view(action.payload.tournament_id));
    } catch (e) {
        console.warn(e.stack);
        yield put(error([{message: e.message}]))
    }
}

export function* tournamentViewSaga() {
    yield takeLatest(VIEW, fetchView);
    yield takeLatest(ADD_GAME, fetchAddGame);
    yield takeLatest(EDIT_GAME, fetchEditGame);
    yield takeLatest(DELETE_GAME, fetchDeleteGame);
}

