import React from 'react';
import {Col, Nav, Row,} from 'reactstrap';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SvoyaIgraGameResultRoundItem from "./SvoyaIgraGameResultRoundItem";
import SvoyaIgraGameResultQuestionTable from "./SvoyaIgraGameResultQuestionTable";
import {addPlayer, getActiveRound, setActiveRound} from "../../../ducks/gameResultView";
import SvoyaIgraGameResultQuestionModal from "./SvoyaIgraGameResultQuestionModal";
import SvoyaIgraGameResultPlayerItem from "./SvoyaIgraGameResultPlayerItem";
import {bindActionCreators} from "redux";
import SvoyaIgraGameResultAddPlayer from "./SvoyaIgraGameResultAddPlayer";
import {getClientsTrigger} from "../../../ducks/ws";
import {unsetActiveRound} from "../../../ducks/gameResultView";
import SvoyaIgraGameResultAnsweredQuestionModal from "./SvoyaIgraGameResultAnsweredQuestionModal";

class SvoyaIgraGameResultView extends React.Component {

    componentDidMount() {
        this.props.unsetActiveRound();
        this.props.getClientsTrigger();
    }

    render() {
        const rounds = this.props.item.rounds
            ? this.props.item.rounds
            : [];
        return (
            <>
                <SvoyaIgraGameResultQuestionModal gameType={this.props.gameType}/>
                <SvoyaIgraGameResultAnsweredQuestionModal gameType={this.props.gameType}/>
                <Row>
                    <Col className={'col-3'}>
                        <h5 className={'text-center'}>Игроки</h5>
                        {this.props.item.players.map((player) =>
                            <SvoyaIgraGameResultPlayerItem key={player._id} item={player}/>)}
                        <div className="my-2">
                            <SvoyaIgraGameResultAddPlayer/>
                        </div>
                    </Col>
                    <Col className={'col-9'}>
                        <Nav pills fill className={'my-2'}>
                            {rounds.map((round) => <SvoyaIgraGameResultRoundItem key={round._id} item={round}/>)}
                        </Nav>
                        <SvoyaIgraGameResultQuestionTable round={this.props.activeRound}/>
                    </Col>
                </Row>
            </>
        );
    }
}

SvoyaIgraGameResultView.propTypes = {
    item: PropTypes.object.isRequired,
    gameType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    activeRound: getActiveRound(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addPlayer,
    setActiveRound,
    getClientsTrigger,
    unsetActiveRound,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultView);
