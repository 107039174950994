//actions

const SET_FULLSCREEN = 'igames/common/SET_FULLSCREEN';
const UNSET_FULLSCREEN = 'igames/common/UNSET_FULLSCREEN';

const initialState = {
    fullscreen: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_FULLSCREEN:
            return {
                ...state,
                fullscreen: true,
            };
        case UNSET_FULLSCREEN:
            return {
                ...state,
                fullscreen: false,
            };
        default:
            return state;
    }
};

//selectors

export function getState(state) {
    return state.common;
}

export function isFullscreen(state) {
    return getState(state).fullscreen;
}

//action creators
export function setFullscreen() {
    return {
        type: SET_FULLSCREEN
    }
}

export function unsetFullscreen() {
    return {type: UNSET_FULLSCREEN}
}
