
class QuestionCategory {
    static create = (round, gameType) => {
        switch (gameType) {
            case 'si':
                return {
                    name: '',
                    round_id: round._id,
                };
        }
    }
}

export default QuestionCategory;