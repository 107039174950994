import React from 'react';
import SvoyaIgraRoundEditItem from "./SvoyaIgraRoundEditItem";
import {
    deleteRound,
    getActiveRoundId,
    getEditRoundItem,
    setActiveRound,
    showEditRoundItem,
} from "../../ducks/gameView";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Card, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {TYPE_SI} from "../../models/GameType";
import DeleteButton from "../DeleteButton";

const SvoyaIgraRoundItem = (props) => {
    if (props.editItem !== null && props.item._id === props.editItem._id) {
        return (
            <div className="my-1">
                <SvoyaIgraRoundEditItem item={props.editItem}/>
            </div>
        );
    }

    let className = 'my-2';
    if (props.item._id === props.activeRoundId) {
        className += ' Active-item';
    }

    return (
        <Card className={className} body onClick={() => props.setActiveRound(props.item._id)}>
            <Row noGutters>
                <Col>
                    <h6>{props.item.name}</h6>
                </Col>
                <Col className={'col-sm-auto'}>
                    <Button color={'link'}
                            onClick={() => props.showEditRoundItem(props.item)}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                    <DeleteButton onDelete={() => props.deleteRound(props.item, TYPE_SI)}/>
                </Col>
            </Row>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    editItem: getEditRoundItem(state),
    activeRoundId: getActiveRoundId(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({showEditRoundItem, deleteRound, setActiveRound}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps) (SvoyaIgraRoundItem);