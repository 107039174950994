import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'reactstrap';
import {
    addQuestionResult,
    addWrongQuestionResult,
    getAddQuestionResultItem,
    getItem
} from "../../../ducks/gameResultView";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class SvoyaIgraGameResultModalAnswerPanel extends Component {

    onCorrectAnswer() {
        this.props.addQuestionResult(this.props.addQuestionResultItem, this.props.gameType);
    }

    onWrongAnswer() {
        const questionResult = Object.assign({}, this.props.addQuestionResultItem);
        questionResult.cost = -this.props.addQuestionResultItem.cost;
        this.props.addWrongQuestionResult(questionResult, this.props.gameType);
    }

    render() {
        return (
            <Row>
                <Col>
                    <Button color={'success'} block onClick={() => this.onCorrectAnswer()}>
                        Верный ответ
                    </Button>
                </Col>
                <Col>
                    <Button color={'warning'} block onClick={() => this.onWrongAnswer()}>
                        Неверный ответ
                    </Button>
                </Col>
            </Row>
        );
    }
}

SvoyaIgraGameResultModalAnswerPanel.propTypes = {
    addQuestionResultItem: PropTypes.object.isRequired,
    gameType: PropTypes.string.isRequired,
    gameResult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    addQuestionResultItem: getAddQuestionResultItem(state),
    gameResult: getItem(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addQuestionResult,
    addWrongQuestionResult,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SvoyaIgraGameResultModalAnswerPanel);
