import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Form, Input, InputGroup, Row} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addQuestion, editQuestion, hideAddQuestionItem, hideEditQuestionItem} from '../../ducks/gameView';
import {TYPE_SI} from "../../models/GameType";
import * as SvoyaIgraQuestionType from "../../models/SvoyaIgraQuestionType";

class SvoyaIgraQuestionEditItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            uploadFile: null,
        };
    }

    setValue(key, value) {
        this.setState((prevState) => ({
            ...prevState,
            item: {
                ...prevState.item,
                [key]: value,
            }
        }));
    }

    setUploadFile(event) {
        let files = event.target.files || event.dataTransfer.files;
        if (!files.length)
            return;
        const file = files[0];
        this.setState((prevState) => ({
            ...prevState,
            uploadFile: file,
        }));
    }

    onSubmit(event) {
        if (this.state.item._id !== undefined) {
            this.props.editQuestion(this.state.item, this.state.uploadFile, TYPE_SI);
        } else {
            this.props.addQuestion(this.state.item, this.state.uploadFile, TYPE_SI);
        }
        event.preventDefault();
    }

    onCancel() {
        if (this.state.item._id !== undefined) {
            this.props.hideEditQuestionItem()
        } else {
            this.props.hideAddQuestionItem()
        }
    }

    render() {
        const item = this.state.item;
        const uploadFile = this.state.uploadFile;
        return (
            <Card body>
                <Form onSubmit={(event) => this.onSubmit(event)}>
                    <Row className={'my-2'}>
                        <Col>
                            <Input
                                type={'textarea'}
                                placeholder={'Текст вопроса'}
                                value={item.question}
                                onChange={(event) => this.setValue('question', event.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className={'my-2'}>
                        <Col>
                            <Input
                                type={'textarea'}
                                placeholder={'Текст ответа'}
                                value={item.answer}
                                onChange={(event) => this.setValue('answer', event.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className={'my-2'}>
                        <Col md={2}>
                            <InputGroup>
                                <Input
                                    value={item.price}
                                    onChange={(event) => this.setValue('price', event.target.value)}
                                    type={'number'}
                                    placeholder={'Ценa'}/>
                            </InputGroup>
                        </Col>
                        <Col md={4}>
                            <InputGroup>
                                <Input
                                    value={item.question_type_id}
                                    onChange={(event) => this.setValue('question_type_id', event.target.value)}
                                    type={'select'}>
                                    {Object.keys(SvoyaIgraQuestionType.available).map(
                                        (key) => {
                                            const value = SvoyaIgraQuestionType.available[key];
                                            return <option key={key} value={key}>{value}</option>
                                        }
                                    )
                                    }
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col>
                            {SvoyaIgraQuestionType.withFile.indexOf(item.question_type_id) !== -1
                                && (
                                <Input type="file" onChange={(event) => this.setUploadFile(event)}/>
                                )
                            }
                        </Col>
                    </Row>
                    <Row className={'my-2'}>
                        <Col>
                            <Button onClick={(event) => this.onSubmit(event)} color={'success'} className={'mx-1'}>
                                Сохранить
                            </Button>
                            <Button onClick={() => this.onCancel()} className={'mx-1'}>
                                Отменить
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

SvoyaIgraQuestionEditItem.propTypes = {
    item: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({hideAddQuestionItem,
        hideEditQuestionItem,
        addQuestion,
        editQuestion}, dispatch);
};

export default connect(null, mapDispatchToProps) (SvoyaIgraQuestionEditItem);
